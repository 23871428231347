import {action, observable} from "mobx";
import _ from "lodash";
import axios from 'axios';
import {RequestModel} from "../models/RequestModel";
import RequestsResponse from "../interfaces/RequestsResponse";

export class RequestStore {
  @observable
  request: RequestModel;

  subscribeStarted: boolean = false;

  @observable
  latestLoadedAt: Date = new Date();

  intervalTimer: any | null = null;

  @action
  public subscribeRequest(requestId: number) {
    this.loadRequest(requestId);

    if (!this.subscribeStarted) {
      if (this.intervalTimer) {
        clearInterval(this.intervalTimer)
      }
      this.intervalTimer = setInterval(this.loadRequest.bind(this, requestId), 60000);
      this.subscribeStarted = true;
    }
  }

  @action
  private async loadRequest(requestId: number) {
    const response = await axios.get(`/api/requests/${requestId}`);
    const result: RequestsResponse = response.data;

    this.replaceRequest(new RequestModel(result));
    this.latestLoadedAt = new Date()
  }

  @action
  private replaceRequest(request: RequestModel) {
    this.request = request;
  }
}

const singleton = new RequestStore();
export default singleton
