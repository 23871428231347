import React, { PureComponent } from "react";
import { observer } from "mobx-react";

interface Props {
  mapApi: any,
  map: any,
  color?: string,
  locations: any
}

class Polyline extends React.Component<Props> {
  // mapapiを使って描画するのでstate相当のlineを自分で管理する
  line: any;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    if (this.line) {
      // propsが更新されるタイミングで一旦lineをnullにする
      this.line.setMap(null)
    }
  }

  componentWillUnmount() {
    if (this.line) {
      this.line.setMap(null)
    }
  }

  render() {
    if (!this.props.mapApi) {
      return null
    }
    // render関数で直接map apiにpolylineを追加
    const Polyline = this.props.mapApi.Polyline;

    const renderedPolyline = this.renderPolyline();
    const paths = { path: this.props.locations };

    this.line = new Polyline(Object.assign({},
      renderedPolyline,
      paths));

    this.line.setMap(this.props.map);

    return null
  }

  renderPolyline() {
    return {
      geodesic: true,
      strokeColor: 'rgba(47,149,220, 0.9)',
      strokeOpacity: 1,
      strokeWeight: 4
    }
  }
}

export default Polyline;
