import React, {ReactNode} from "react";
import {PickMode} from "../MapAreaPicker";

interface Props {
    pickMode: PickMode
    setPickMode: (mode: PickMode) => void
    pathsToolbox: ReactNode
    townBordersToolbox: ReactNode
    hexToolbox: ReactNode
}

export default function MAPToolboxContent(props: Props) {
    const {pickMode, setPickMode, pathsToolbox, townBordersToolbox, hexToolbox} = props

    return (
        <div style={{width: "21em"}}>
            <h6 className={"text-left font-weight-bold text-gray-500"}>選択モード</h6>
            <div className={"accordion"}>
                <div className={"card"}>
                    <h2 className="card-header py-0 px-0">
                        <button
                            className={`btn btn-link text-left ${pickMode === "Paths" ? "collapsed" : ""}`}
                            onClick={() => setPickMode("Paths")}
                            style={{width: "100%"}}
                        >
                            パスで選択する
                        </button>
                    </h2>
                    <div className={`accordion-collapse collapse ${pickMode === "Paths" ? "show" : ""}`}>
                        <div className="card-body px-2 py-2">
                            {pathsToolbox}
                        </div>
                    </div>
                </div>
                <div className={"card"}>
                    <h2 className="card-header py-0 px-0">
                        <button
                            className={`btn btn-link text-left  ${pickMode === "TownBorder" ? "collapsed" : ""}`}
                            onClick={() => setPickMode("TownBorder")}
                            style={{width: "100%"}}
                        >
                            町域で選択
                        </button>
                    </h2>
                    <div className={`accordion-collapse collapse ${pickMode === "TownBorder" ? "show" : ""}`}>
                        <div className="card-body px-2 py-2">
                            {townBordersToolbox}
                        </div>
                    </div>
                </div>
                <div className={"card"}>
                    <h2 className="card-header py-0 px-0">
                        <button
                            className={`btn btn-link text-left  ${pickMode === "Hex" ? "collapsed" : ""}`}
                            onClick={() => setPickMode("Hex")}
                            style={{width: "100%"}}
                        >
                            ヘックスで選択
                        </button>
                    </h2>
                    <div className={`accordion-collapse collapse ${pickMode === "Hex" ? "show" : ""}`}>
                        <div className="card-body px-2 py-2">
                            {hexToolbox}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}