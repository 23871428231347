import React from "react";
import {observer} from "mobx-react";
import carryStaffOverlookStore from '../stores/CarryStaffOverlookStore';
import requestsStore from "../stores/RequestsStore";
import _ from 'lodash'
import {RequestModel} from "../models/RequestModel";
import format from "date-fns/format";

interface Props {
}

interface State {
  searchCondition: string | null;
}

class CarryStaffOverlookRequestList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchCondition: ""
    }
  }

  componentDidMount(): void {
  }

  render() {
    if (_.isEmpty(requestsStore.requests)) {
      return (
        <div>
          {this.renderSearchBox()}
          有効な依頼がありませんでした。
        </div>
      )
    }

    return (
      <div>
        {this.renderSearchBox()}
        {this.renderServiceAreaList()}
      </div>
    );
  }

  private renderServiceAreaList() {
    return _.map(requestsStore.requests, (request) => {

      if (!this.matchSearchCondition(request)) {
        return null
      }
      return (
        <div className={"border-left-info pl-4 mt-3"} key={request.id}>
          <a href={`/requests/${request.id}/edit`}>
            <div className={"request-name"}>ID：#{request.id}   {request.statusText}</div>
          </a>
          {this.renderDeliveryTimeAt(request)}
          {this.renderCarryStaff(request)}
          <div className={"request-name"}>店舗様名称：{request.sender.name}</div>
          <div className={"request-address"}>住所名称：{request.sender.address}</div>
          <div className={"request-name"}>お客様様名称：{request.receiver.name}</div>
          <div className={"request-address"}>住所名称：{request.receiver.address}</div>
        </div>
      )
    })
  }

  private renderSearchBox() {
    return (
      <React.Fragment>
        <div className="form-group form-inline mb-2">
          <button className="btn btn-primary ml-2" onClick={() => {
            carryStaffOverlookStore.viewAllRequests();
          }}>
            <i className={"fas fa-eye"}/>
          </button>
          <button className="btn btn-primary ml-2" onClick={() => {
            carryStaffOverlookStore.hiddenAllRequests()
          }}>
            <i className={"fas fa-eye-slash"}/>
          </button>
        </div>
        <div className="form-group mb-2">
          <input type="text" className="form-control" value={this.state.searchCondition}
                 placeholder={"スペースで複数指定"}
                 onChange={(event) => {
                   this.setState({
                     searchCondition: event.target.value
                   })
                 }}
          />
        </div>
      </React.Fragment>
    )
  }

  private matchSearchCondition(request: RequestModel) {
    const searchConditions = this.state.searchCondition.trim().split(/[　 ]/);
    if (searchConditions.length == 0) {
      return true
    }

    let searchText = `${request.sender.name}_${request.receiver.name}_${request.carryStaff ? request.carryStaff.name : ""}`

    return _.some(searchConditions, (searchCondition) => {
      return (searchText.indexOf(searchCondition) > -1)
    })
  }

  private renderCarryStaff(request: RequestModel) {
    if (request.carryStaff) {
      return (
        <div className={"request-name"}>配達スタッフ：{request.carryStaff.name}</div>
      )
    }

    return (
      <div className={"no-assign-carry-staff"}>配達スタッフ：(未アサイン)</div>
    )
  }

  private renderDeliveryTimeAt(request: RequestModel) {
    if (request.deliveryTimeAt) {
      return (
        <div className={"request-name"}>配達時間：{format(request.deliveryTimeAt, 'yyyy/MM/dd HH:mm')}</div>
      )
    }
  }
}

export default observer(CarryStaffOverlookRequestList);
