import React, {useEffect, useState} from "react";
import {Coords} from "google-map-react";
import {TownBorder} from "../../../interfaces/TownBorder";

interface Props {
    fetchTownBorders: () => Promise<void>
    centerPosition: Coords
    onPanToCenter: (coords: Coords) => void
    onClickSearchAddress: () => void
    centerTown: TownBorder
}

export default function MAPToolboxContentForTownBorder(props: Props) {
    const {fetchTownBorders, centerPosition, onPanToCenter, onClickSearchAddress, centerTown} = props

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!centerTown) return
        setLoading(true)
        fetchTownBorders()
            .then(() => {
            })
            .finally(() => setLoading(false))
    }, [centerTown])

    const handleClickExpansionButton = () => {
        setLoading(true)
        fetchTownBorders()
            .then(() => {
            })
            .finally(() => setLoading(false))
    }

    const handleClickPanToCenter = () => {
        onPanToCenter(centerPosition)
    }

    return (
        <div>
            {
                centerTown &&
                <button className="btn btn-light btn-block text-xs" onClick={handleClickPanToCenter}>
                    <i className="fas fa-crosshairs mr-1"></i>
                    <span>{centerTown.pref + centerTown.city + centerTown.town}</span>
                </button>
            }
            <button className="btn btn-light btn-block text-xs" onClick={onClickSearchAddress}>
                <i className="fas fa-search mr-1"></i>住所を検索する
            </button>
        </div>
    )
}