import React from "react";

interface Props {
  mapApi: any,
  map: any,
  color?: string,
  fromLat: number,
  fromLng: number,
  toLat: number,
  toLng: number
}

class RequestConnectPolyline extends React.Component<Props> {
  // mapapiを使って描画する場合、reactのlife cycleとタイミングが合わない
  // そのため、state相当のlineを自分で管理する
  line: any = null;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    // propsが更新されば場合は既存のlineをmap上から削除
    if (this.line) {
      this.line.setMap(null)
    }
  }

  componentWillUnmount() {
    // コンポーネントが消されたとき、ラインが地図上からも表示されないようにする
    if (this.line) {
      this.line.setMap(null)
    }
  }

  render() {
      this.renderConnect();
      return null
  }

  private renderConnect() {
    this.line = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderArrowPolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(this.props.fromLat, this.props.fromLng),
            new this.props.mapApi.LatLng(this.props.toLat, this.props.toLng),
          ],
          map: this.props.map
        }
      )
    );

  }

  renderArrowPolyline() {
    return {
      geodesic: true,
      strokeColor: 'rgba(47,149,220, 0.9)',
      strokeOpacity: 0,
      icons: [
        this.dotArrowIcon()
      ]
    }
  }

  private dotArrowIcon() {
    return {
      icon: {
        path: this.props.mapApi.SymbolPath.FORWARD_OPEN_ARROW,
        strokeOpacity: 1,
        strokeWeight: 2,
        scale: 1
      },
      offset: '0',
      repeat: '20px'
    }
  }

}

export default RequestConnectPolyline;
