import React from "react";
import type {PickMode} from "../MapAreaPicker";

interface Props {
    loading: boolean
    onClickDestroy: () => void
    onClickRemove: () => void
    onClickAdd: () => void
    cantUpdate?: boolean
    canDestroy: boolean
    pickMode: PickMode
}

export default function MAPActionsContent(props: Props) {
    const {
        loading,
        onClickDestroy,
        onClickRemove,
        onClickAdd,
        cantUpdate,
        canDestroy
    } = props

    return (
        <div className={"d-flex"}>
            {
                canDestroy &&
                <>
                    <button className="btn btn-outline-danger" style={{width: "6em"}}
                            onClick={onClickDestroy}
                            disabled={loading}
                    >初期化
                    </button>
                    <div style={{borderLeft: "1px solid #ccc", margin: "0 16px"}}/>
                </>
            }

            <>

                <button
                    className="btn btn-danger mr-2"
                    style={{width: "9.5em"}}
                    onClick={onClickRemove}
                    disabled={loading || cantUpdate}
                >選択エリアを除外
                </button>
                <button
                    className="btn btn-primary"
                    style={{width: "9.5em"}}
                    onClick={onClickAdd}
                    disabled={loading || cantUpdate}
                >
                    選択エリアを追加
                </button>
            </>
        </div>
    )

}