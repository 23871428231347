import React from "react";
import axios from 'axios';
import {observer} from "mobx-react";
import 'react-toastify/dist/ReactToastify.css';
import DeliveryArea from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithTownBorder from "./DeliveryAreaMapWithTownBorder";

interface Props {
  vendorCompanyId: number
  deliveryArea: DeliveryArea
}

class DeliveryAreaMapWithTownBorderVendorCompany extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <DeliveryAreaMapWithTownBorder
        updateDeliveryArea={async (deliveryAreaWkt: string) => {
          return await axios.post(
            `/api/vendor_companies/${this.props.vendorCompanyId}/update_delivery_area_wkt`,
            {delivery_area: deliveryAreaWkt});
        }}
        deliveryArea={this.props.deliveryArea}
        deleteDeliveryArea={async ()=> {
          return await axios.post(`/api/vendor_companies/${this.props.vendorCompanyId}/delete_delivery_area`);
        }}
      />
    );
  }

}

export default observer(DeliveryAreaMapWithTownBorderVendorCompany);
