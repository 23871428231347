import "jquery.easing/jquery.easing.min.js";
import "chart.js"
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import {configure} from 'mobx';

configure({
  enforceActions: 'observed'
});

const gon = window.gon || []

if (gon && gon.onesignal_external_user_id) {
  var OneSignal = window.OneSignal || [];
  OneSignal.push(function () {
    OneSignal.init({
      appId: gon.onesignal_app_id,
      notifyButton: {
        enable: true,
        size: "large"
      },
    });

    OneSignal.on('subscriptionChange', function (isSubscribed) {
      if (isSubscribed) {
        console.log("Notification subscribe")
        OneSignal.setExternalUserId(gon.onesignal_external_user_id);
        OneSignal.sendTag("organization_id", gon.onesignal_external_organization_id);
        OneSignal.sendTag("user_id", gon.onesignal_external_user_id);
      }
    });

    OneSignal.showNativePrompt();
  });
}
