import React from "react";
import {observer} from "mobx-react";
import _ from 'lodash'
import 'jquery-slimscroll';
import format from 'date-fns/format';
import LocationsResponse from "../interfaces/LocationsResponse";
import carryStaffLocationHistoriesStore from "../stores/CarryStaffLocationHistoriesStore";
interface Props {
  carryStaffId: number
  locations: LocationsResponse[]
}

class CarryStaffPositionList extends React.Component<Props> {
  componentDidMount(): void {
    // マップコンポーネントと共有するため、ストアに保存
    carryStaffLocationHistoriesStore.setStaffLocations(this.props.carryStaffId, this.props.locations);
  }

  render() {
    return (
      <div
        className={'carry-staff-position-container'}>
        <table
          style={tableStyle}
        >
          <thead>
            <tr>
              <th
                style={dateHeader}
              >
                時刻
              </th>
              <th
                style={dateHeader}
              >
                緯度
              </th>
              <th
                style={dateHeader}
              >
                経度
              </th>
              <th
                style={dateHeader}
              >
                バッテリー
              </th>
              <th
                style={dateHeader}
              >
                状態
              </th>
            </tr>
          </thead>
          <tbody>
            {this.createCarryStaffPositions()}
          </tbody>
        </table>
      </div>
    )
  }

  private createCarryStaffPositions() {
    const locations = carryStaffLocationHistoriesStore.staffLocations[this.props.carryStaffId];

    return _.map(locations, (location) => {
      return (
        <tr key={location.id}>
          <td
            style={dateHeader}
          >
            {format(location.sent_at, 'yyyy/MM/dd')}
            <br/>
            {format(location.sent_at, 'HH:mm:ss')}
          </td>
          <td
            style={coordText}
          >
            {location.lat}
          </td>
          <td
            style={coordText}
          >
            {location.lng}
          </td>
          <td
            style={coordText}
          >
            {location.battery_level < 0 ? '-' : location.battery_level}
          </td>
          <td
            style={coordText}
          >
            {location.activity}
          </td>
        </tr>
      )
    })
  }
}

const dateHeader = {
  fontSize: 14,
  fontWeight: 'bold' as 'bold',
  border: "1",
  borderWidth: "1px",
  borderStyle: "solid"
};

const coordText = {
  fontSize: 14,
  fontWeight: 'normal' as 'normal',
  marginLeft: 20,
  border: "1",
  borderWidth: "1px",
  borderStyle: "solid"
};

const tableStyle = {
  width: "500px",
  border: "1",
  borderWidth: "1px",
  borderStyle: "solid",
};

const colStyle = {
  border: "1",
  borderWidth: "1px",
  borderStyle: "solid"
}

export default observer(CarryStaffPositionList);
