import React from "react";
import {observer} from "mobx-react";
import GoogleMapReact from 'google-map-react';
import MapAttributes from "../../constants/MapAttributes";
import Address from "../../interfaces/Address";
import AddressMarker from "./AddressMarker";
import TemporaryAddressMarker from "./TemporaryAddressMarker";

declare var gon: any;

interface Props {
  address: Address
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean
}


class AddressMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false
    };
  }

  componentDidMount(): void {
  }

  render() {
    const { address } = this.props;
    const center = address.fixed ? { lat: address.lat, lng: address.lng} : { lat: address.temporary_lat, lng: address.temporary_lng}

    return (
      <div className={'request-map-container'}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={address}
          defaultZoom={16}
          center={center}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
        >
          {this.renderTemporaryAddressMarker()}
          {this.renderAddressMarker()}
        </GoogleMapReact>
      </div>
    );
  }

  renderAddressMarker() {
    const { address } = this.props;
      return (
        <AddressMarker
          lat={address.lat}
          lng={address.lng}
        />
      )
  }
  renderTemporaryAddressMarker() {
    const { address } = this.props;
    if (!address.fixed) {
      return (
        <TemporaryAddressMarker
          lat={address.temporary_lat}
          lng={address.temporary_lng}
        />
      )
    }
  }

}

export default observer(AddressMap);
