import React from "react";
const style = {boxShadow: "1px 1px 5px rgba(0, 0, 0, 0.4)", fontSize: 14, width: 320}
export const SaveSuccessAlert = (
    <div
        className="alert alert-success mb-1"
        style={style}
        role="alert">
        <i className="fas fa-check mr-2"/>
        更新しました!
    </div>
)
export const SaveFailureAlert = (
    <div
        className="alert alert-danger mb-1"
        style={style}
        role="alert">
        <i className="fas fa-exclamation-triangle mr-2"/>
        更新に失敗しました
    </div>
)
export const TargetNotExistAlert = (
    <div
        className="alert alert-danger mb-1"
        style={style}
        role="alert">
        <i className="fas fa-exclamation-triangle mr-2"/>
        更新対象のエリアがありません
    </div>
)
export const DestroySuccessAlert = (
    <div
        className="alert alert-info mb-1"
        style={style}
        role="alert">
        <i className="fas fa-trash mr-2"/>
        削除しました
    </div>
)

export const DestroyFailureAlert = (
    <div
        className="alert alert-danger mb-1"
        style={style}
        role="alert">
        <i className="fas fa-exclamation-triangle mr-2"/>
        削除に失敗しました
    </div>
)
export const NotNullFailureAlert = (
    <div
        className="alert alert-danger mb-1"
        style={style}
        role="alert">
        <i className="fas fa-exclamation-triangle mr-2"/>
        選択エリアを空にすることはできません
    </div>
)