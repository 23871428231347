import React from "react";
import axios, {AxiosResponse} from 'axios';
import { h3ToGeoBoundary, geoToH3, kRing } from "h3-js";
import { observer } from "mobx-react";
import { csrfToken } from 'rails-ujs';
import { Slide, ToastContainer, toast } from 'react-toastify';
import RangeSlider from 'react-bootstrap-range-slider';
// @ts-ignore
import Wkt from 'wicket';
import DeliveryArea, { OtherArea } from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithOtherAreas from "./DeliveryAreaMapWithOtherAreas";


interface Props {
  deliveryArea: DeliveryArea
  updateDeliveryArea: (targetArea: string) => Promise<AxiosResponse<any>>
}

interface State {
  map: any
  mapApi: any
  mapLoaded: boolean
  deliveryAreaWkt: string
  hexAreas: OtherArea[]
  resolution: number
}

type ApiResponse = {
  delivery_area: string
}

class DeliveryAreaMapWithHexSelection extends React.Component<Props, State> {
  // 画面に解像度の数字をそのまま出すとユーザーにはおそらく意味がわからないので、
  // 分かりやすくなる様に、解像度の数字をひっくり返すためのもの
  invertSum = 10;

  wkt: any;
  constructor(props: Props) {
    super(props);
    this.wkt = new Wkt.Wkt()
    const hexAreas = this.createHexAreas(
      props.deliveryArea.lat, props.deliveryArea.lng, 9
    )
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      deliveryAreaWkt: props.deliveryArea.area_wkt,
      hexAreas: hexAreas,
      resolution: 9
    };
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.onClickHexArea = this.onClickHexArea.bind(this)
  }

  componentDidMount() {
  }

  createHexAreas = (lat: number, lng: number, resolution: number) => {
    // 解像度とk-Ringの対応表
    // 各解像度とhexの一辺の距離 https://h3geo.org/docs/core-library/restable
    const resSizeMap = { 5: 1, 6: 3, 7: 8, 8: 22, 9: 60 }
    const hexAreas = kRing(
      geoToH3(lat, lng, resolution), resSizeMap[resolution]
    ).map(ringIndex => {
      const coordinates = h3ToGeoBoundary(ringIndex, true)
      const ringObj = {coordinates: [coordinates], type: "Polygon"}
      const ringWkt = JSON.stringify(ringObj)
      const wktObj = this.wkt.read(ringWkt)
      return {
        key: ringIndex,
        areaWkt: wktObj.write()
      } as OtherArea
    })
    return hexAreas
  }

  update = async (targetArea: string) => {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide
    };
    try  {
      const res = await this.props.updateDeliveryArea(targetArea);
      toast.success("更新しました。", options);
      return res;
    } catch (e) {
      toast.error("更新に失敗しました。", options);
    }
  }

  async onClickHexArea(areaWkt: string) {
    const res = await this.update(areaWkt)
    this.setState({
      deliveryAreaWkt: (res.data as ApiResponse).delivery_area
    })
  }

  onChangeSlider = (value: number) => {
    const hexAreas = this.createHexAreas(
      this.props.deliveryArea.lat, this.props.deliveryArea.lng, value
    )
    this.setState({resolution: value, hexAreas: hexAreas})
  }

  render() {
    const { deliveryArea } = this.props;
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center py-0 px-3" style={{width: 300}}>
          <div>ヘックスサイズ</div>
          <RangeSlider
            min={1}
            max={5}
            value={this.invertSum - this.state.resolution}
            tooltipPlacement={"top"}
            onChange={e => this.onChangeSlider(this.invertSum - Number(e.target.value))}
          />
        </div>
        <DeliveryAreaMapWithOtherAreas
          centerLat={deliveryArea.lat}
          centerLng={deliveryArea.lng}
          deliveryAreaWkt={this.state.deliveryAreaWkt}
          otherAreas={this.state.hexAreas}
          onClickOtherArea={async (hexIndex, areaWkt) => {await this.onClickHexArea(areaWkt)}}
        ></DeliveryAreaMapWithOtherAreas>
        <ToastContainer />
      </div>
    );
  }
}

export default observer(DeliveryAreaMapWithHexSelection);
