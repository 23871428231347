import { action, observable, set } from "mobx";
import _ from "lodash";
import axios from 'axios';
import OfficesResponse from "../interfaces/OfficesResponse";
import VendorCompanyResponse from "../interfaces/VendorCompanyResponse";
import { VisibleVendorCompanyModel } from "../models/VisibleVendorCompanyModel";
import { VisibleOfficeModel } from "../models/VisibleOfficeModel";

interface NewMarker {
  lat: number,
  lng: number,
  visible: boolean,
  id: number
}

declare var google: any;

export class OverlookStore {
  visibleOffices = observable<VisibleOfficeModel>([]);
  visibleVendorCompanies = observable<VisibleVendorCompanyModel>([]);
  visibleNewMarkers = observable<NewMarker>([]);

  @observable
  vendorCompanyRange: number = 30;

  @observable
  officeRange: number = 1500;

  @observable
  newMarkerRange: number = 1500;

  newMarkerCount: number = 0;

  map: any;

  constructor() {
    this.loadOffices();
    this.loadVendorCompanies();
    set(this.visibleNewMarkers, []);
  }

  @action
  public setMap(map) {
    this.map = map;
  }

  @action
  public async loadOffices() {
    const response = await axios.get('/api/offices', {
      params: {}
    });
    const results = response.data;

    const offices = _.map(results, (result: OfficesResponse) => {
      return new VisibleOfficeModel(result)
    });

    this.replaceOffices(offices)
  }

  @action
  private replaceOffices(offices) {
    this.visibleOffices.replace(offices)
  }

  @action
  public async loadVendorCompanies() {
    const response = await axios.get('/api/vendor_companies', {
      params: {}
    });
    const results = response.data;

    const vendorCompanies = _.map(results, (result: VendorCompanyResponse) => {
      return new VisibleVendorCompanyModel(result)
    });

    this.replaceVendorCompanies(vendorCompanies)
  }

  @action
  private replaceVendorCompanies(vendorCompanies) {
    this.visibleVendorCompanies.replace(vendorCompanies)
  }

  @action
  private replaceNewMarkers(newMarkers) {
    this.visibleNewMarkers.replace(newMarkers)
  }

  @action
  public toggleVendorCompanyVisible(visibleVendorCompanyModel) {
    const visibleVendorCompany = _.find(this.visibleVendorCompanies, (object) => {
      return object.id == visibleVendorCompanyModel.id
    })
    visibleVendorCompany.visible = !visibleVendorCompany.visible;
    this.replaceVendorCompanies(this.visibleVendorCompanies);
  }

  @action
  public toggleOfficeVisible(cisibleOfficeModel) {
    const visibleVendorOffice = _.find(this.visibleOffices, (object) => {
      return object.id == cisibleOfficeModel.id
    })
    visibleVendorOffice.visible = !visibleVendorOffice.visible;
    this.replaceOffices(this.visibleOffices);
  }

  @action
  public toggleNewMarker(newMarker) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return newMarker.id == object.id
    })
    findNewMarker.visible = !findNewMarker.visible;
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public viewAllVendorCompanies() {
    _.each(this.visibleVendorCompanies, (object) => {
      object.visible = true
    })
    this.replaceVendorCompanies(this.visibleVendorCompanies);
  }

  @action
  public hiddenAllVendorCompanies() {
    _.each(this.visibleVendorCompanies, (object) => {
      object.visible = false
    })
    this.replaceVendorCompanies(this.visibleVendorCompanies);
  }

  @action
  public viewAllOffices() {
    _.each(this.visibleOffices, (object) => {
      object.visible = true
    })
    this.replaceOffices(this.visibleOffices);
  }

  @action
  public hiddenAllOffices() {
    _.each(this.visibleOffices, (object) => {
      object.visible = false
    })
    this.replaceOffices(this.visibleOffices);
  }

  @action
  public viewAllNewMarkers() {
    _.each(this.visibleNewMarkers, (object) => {
      object.visible = true
    })
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public hiddenAllNewMarkers() {
    _.each(this.visibleNewMarkers, (object) => {
      object.visible = false
    })
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public setVendorCompanyRange(meter: number) {
    this.vendorCompanyRange = meter
  }

  @action
  public setOfficeRange(meter: number) {
    this.officeRange = meter
  }

  @action
  public setNewMarkerRange(meter: number) {
    this.newMarkerRange = meter
  }

  @action
  public addNewMarker(address: string) {
    this.newMarkerCount++;
    const newMarkers = this.visibleNewMarkers.slice()
    newMarkers.push({
      lat: this.map.getCenter().lat(),
      lng: this.map.getCenter().lng(),
      visible: true,
      id: this.newMarkerCount
    })
    this.replaceNewMarkers(newMarkers);
  }

  @action
  public changeNewMarkerPosition(newMarker: NewMarker, lat: number, lng: number) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return newMarker.id == object.id
    })
    findNewMarker.lat = lat;
    findNewMarker.lng = lng;
    
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  public moveCenter(address: string) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
        address: address,
        region: 'jp'
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.map.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          })
        }
        console.log(status, results)
      })
  }
}

const singleton = new OverlookStore();
export default singleton
