import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";
import React from "react";
import axios from "axios";
import {csrfToken} from 'rails-ujs';
import Wkt from 'wicket';
import {observer} from "mobx-react";
import {Coords} from "google-map-react";
import {parse} from "wkt"
import {centerOfMass} from "@turf/turf"

declare var gon: any;

interface Props {
    targetId: number
    lat: number,
    lng: number,
    wkt: string
}

interface State {
    wkt: string | null
    center: Coords
}

class ServiceAreaMapEditor extends React.Component<Props, State> {
    wkt: any = null;

    constructor(props: Props) {
        super(props);

        const {lat, lng, wkt} = props
        const center = getCenterCoords(wkt)
        this.state = {
            wkt,
            center: center ? center : {lat, lng}
        };

        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
        this.wkt = new Wkt.Wkt();
    }

    updateByWkt = async (targetArea) => {
        const isValid = this.validateWktText(targetArea)
        return new Promise<void>(async (resolve, reject) => {
            try {

                if (!isValid) throw new Error("wktの形式が不正です")
                const res = await axios.post(
                    `/api/service_areas/${this.props.targetId}/update_within_area_wkt`,
                    {within_area_wkt: targetArea})
                this.setState({
                    wkt: res.data.within_area_wkt,
                })
                resolve()
            } catch (e) {
                console.error(e)
                reject(e)
            }
        })
    }

    validateWktText = (wktText: string) => {
        try {
            this.wkt.read(wktText);
        } catch (error) {
            return false
        }
        return true
    }

    render() {
        const {wkt, center} = this.state
        return (
            <div style={{width: "100%", height: 720}}>
                <MapAreaPicker
                    gmapsApiKey={gon.google_api_key}
                    center={center}
                    wkt={wkt}
                    onUpdateByWkt={this.updateByWkt}
                    notNull={true}
                />
            </div>
        )
    }
}

export default observer(ServiceAreaMapEditor)

// ポリゴン全体の中心点を取得する
const getCenterCoords = (wkt: string): Coords | undefined => {
    try {
        const geoJSON = parse(wkt)
        const centerPoint = centerOfMass(geoJSON)
        return {
            lat: centerPoint.geometry.coordinates[1],
            lng: centerPoint.geometry.coordinates[0]
        }
    } catch (e) {
        console.error(e)
        return undefined
    }
}