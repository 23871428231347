import React from "react";
import axios from 'axios';
import {observer} from "mobx-react";
import DeliveryArea from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithHexSelection from "./DeliveryAreaMapWithHexSelection";

interface Props {
  deaasTemplateId: number
  deliveryArea: DeliveryArea
}

class DeliveryAreaMapWithHexSelectionDeaasTemplate extends React.Component<Props> {
  render() {
    return (
      <DeliveryAreaMapWithHexSelection
        deliveryArea={this.props.deliveryArea}
        updateDeliveryArea={async (targetArea) => {
          return await axios.post(
            `/api/deaas_templates/${this.props.deaasTemplateId}/update_delivery_area`,
            {
              area: targetArea
            })
        }}
      />
    );
  }
}

export default observer(DeliveryAreaMapWithHexSelectionDeaasTemplate);
