import React from "react";
import format from 'date-fns/format'

declare var gon: any;

interface Props {
}

interface State {
  latestLoadedAt: Date,
  intervalId: any;
}


export default class RequestReloadButton extends React.Component<Props, State> {
  reload = () => {
    window.location.reload();
    this.setState({latestLoadedAt: new Date()})
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      latestLoadedAt: new Date(),
      intervalId: null
    };
  }

  componentDidMount(): void {
    const intervalId = setInterval(this.reload, 60000)
    this.setState({
      intervalId: intervalId
    })
  }

  componentWillUnmount(): void {
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <>
        <span className="mr-2">最終読込日時</span>
        <span>{format(this.state.latestLoadedAt, 'HH:mm:ss')}</span>
      </>
    );
  }
}
