import React from "react";
import { observer } from "mobx-react";
import spotRemarkOverlookStore from '../stores/SpotRemarkOverlookStore';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";

import SpotRemarkTempMarker from "./SpotRemarkTempMarker";
import WktMultiPolygon from "./WktMultiPolygon";

import $ from 'jquery';


declare var gon: any;

interface Props {
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean,

  centerLat: number;
  centerLng: number;
  prevCenterLat: number; // Zoom変更かつ拡大の場合は変更不要
  prevCenterLng: number;
  prevZoom: number;
}

class SpotRemarkOverlookMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const defaultLat = this.getDefaultCenterLat();
    const defaultLng = this.getDefaultCenterLng();

    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,

      centerLat: defaultLat,
      centerLng: defaultLng,
      prevCenterLat: defaultLat,
      prevCenterLng: defaultLng,
      prevZoom: this.getDefaultZoom(),
    };

  }

  componentDidMount(): void {
    navigator.geolocation.getCurrentPosition((result: any) => {
      // this.setState({
      //   centerLat: result.coords.latitude,
      //   centerLng: result.coords.longitude,
      // })
    }, () => {
    });
  }

  render() {
    return (
      <div className={'request-map-container'}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          onChange={(e) => {this.onChangeMap(e)}}
          defaultCenter={{
            lat: this.state.centerLat,
            lng: this.state.centerLng
          }}
          defaultZoom={this.state.prevZoom}
          center={{
            lat: this.state.centerLat,
            lng: this.state.centerLng
          }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={
            ({ map, maps }) => {
              spotRemarkOverlookStore.setMap(map);
              // sizeが取れないので、jqueryで取る
              const mapEle = $('#spot-remarks-overlook-map');
              const searchRange: number
                = this.calcSearchRadius(Number(map.getCenter().lat()), map.zoom, mapEle.width(), mapEle.height());
              spotRemarkOverlookStore.setMarkerRange(searchRange);
              // spot情報の初期ロード
              spotRemarkOverlookStore.loadMarkers(Number(map.getCenter().lat()), Number(map.getCenter().lng()), searchRange);

              this.setState({
                map: map,
                mapApi: maps,
                mapLoaded: true
              })
            }
          }
        >
          {this.renderMarkers()}
          {this.renderPolygon()}

        </GoogleMapReact>
      </div>
    );
  }

  private onChangeMap(e) {
    // console.log(e);
    if (this.state.map === undefined || this.state.map === null) {
      return;
    }
    const curLat: number = Number(e.center.lat);
    const curLng: number = Number(e.center.lng);
    const curZoom: number = Number(e.zoom);

    this.setCurrentLatLngZoomToLocalStrage(curLat, curLng, curZoom);

    if (curLat === this.state.prevCenterLat && curLng === this.state.prevCenterLng) {
      if (curZoom > this.state.prevZoom) { // 単に拡大の場合は再ロード不要
        return;
      }
      // 縮小の場合は読込済みのprevZoomより縮小されたら読み込みなおす
    }

    const metersPerPixel = Math.cos(curLat * Math.PI/180) * 2 * Math.PI * 6378137 / (256 * Math.pow(2, e.zoom));
    // console.log("meter per pixel: " + metersPerPixel);
    const searchRadius: number
      = this.calcSearchRadius(curLat, curZoom, Number(e.size.width), Number(e.size.height));
    spotRemarkOverlookStore.setMarkerRange(searchRadius);

    this.state.map.setCenter({lat: curLat, lng: curLng});
    this.setState({prevCenterLat: curLat, prevCenterLng: curLng, prevZoom: curZoom});
    spotRemarkOverlookStore.loadMarkers(curLat, curLng, searchRadius);
  }

  // 検索半径は見えている領域全て(対角線の半分を半径とする）
  // 距離計算の参考に利用
  // https://gist.github.com/ryanhanwu/4dbcdbdf384f5a3cca1f
  private calcSearchRadius = (lat: number, zoom: number, width: number, height: number): number => {
    const metersPerPixel = Math.cos(lat * Math.PI/180) * 2 * Math.PI * 6378137 / (256 * Math.pow(2, zoom));
    // console.log("meter per pixel: " + metersPerPixel);
    const searchRadius: number
      = metersPerPixel * Math.sqrt(Math.pow(width, 2) +  Math.pow(height, 2)) / 2;
    // console.log("searchRadius: " + searchRadius);
    return searchRadius;
  }

  /** スポット情報一覧では、行き先修正がある場合の赤いマーカーのみ表示 */
  private renderMarkers() {
    return _.map(spotRemarkOverlookStore.visibleMarkers.slice(), (visibleMarker) => {
      if (!visibleMarker.visible || visibleMarker.model.useDestPoint === 0) {
        return
      }
      return (
        <SpotRemarkTempMarker
          key={visibleMarker.id}
          lat={visibleMarker.model.destLat}
          lng={visibleMarker.model.destLng}
          isCenter={true}
          editable={false}
          markerKey={visibleMarker.id}
          calcMenuPosition={null}
          onClickDelete={null}
        />
      )
    })
  }

  private renderPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null
    }
    const markers: { lat: number, lng: number }[] = [];
    return _.map(spotRemarkOverlookStore.visibleMarkers.slice(), (marker) => {
      if (!marker.visible) {
        return
      }
      // console.log(marker.model.withinAreaWkt)
      return (
        <WktMultiPolygon
          key={marker.id}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={marker.model.withinAreaWkt}
        />
      )
    })
  }

  private setCurrentLatLngZoomToLocalStrage(lat, lng, zoom) {
    localStorage.setItem(this.getDefaultCenterLatKey(), lat);
    localStorage.setItem(this.getDefaultCenterLngKey(), lng);
    localStorage.setItem(this.getDefaultZoomKey(), zoom);
  }

  private getDefaultCenterLatKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_SPOTREMARKOVERLOOKMAP_CENTER_LAT + gon.current_user_id;
  }

  private getDefaultCenterLngKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_SPOTREMARKOVERLOOKMAP_CENTER_LNG + gon.current_user_id;
  }

  private getDefaultZoomKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_SPOTREMARKOVERLOOKMAP_ZOOM + gon.current_user_id;
  }

  private getDefaultCenterLat(): number {
    let lat = Number(localStorage.getItem(this.getDefaultCenterLatKey()));
    if (lat <= 1) {
      lat = MapAttributes.SHIBUYA_STATION_LATITUDE;
    }
    return lat;
  }

  private getDefaultCenterLng(): number {
    let lng = Number(localStorage.getItem(this.getDefaultCenterLngKey()));
    if (lng <= 1) {
      lng = MapAttributes.SHIBUYA_STATION_LONGITUDE;
    }
    return lng;
  }

  private getDefaultZoom(): number {
    let zoom: number = Number(localStorage.getItem(this.getDefaultZoomKey()));
    if (zoom <= 1) {
      zoom = MapAttributes.SPOTREMARK_DEFAULT_ZOOM;
    }
    return zoom;
  }

}

export default observer(SpotRemarkOverlookMap);
