import React from "react";
import axios from 'axios';
import {observer} from "mobx-react";
import 'react-toastify/dist/ReactToastify.css';
import DeliveryArea from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithTownBorder from "./DeliveryAreaMapWithTownBorder";

interface Props {
  deaasTemplateId: number
  deliveryArea: DeliveryArea
}

class DeliveryAreaMapWithTownBorderDeaasTemplate extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <DeliveryAreaMapWithTownBorder
        updateDeliveryArea={async (deliveryAreaWkt: string) => {
          return await axios.post(
            `/api/deaas_templates/${this.props.deaasTemplateId}/update_delivery_area_wkt`,
            {delivery_area: deliveryAreaWkt});
        }}
        deliveryArea={this.props.deliveryArea}
        deleteDeliveryArea={async () => {
          return await axios.post(`/api/deaas_templates/${this.props.deaasTemplateId}/delete_delivery_area`);
        }}
      />
    );
  }

}

export default observer(DeliveryAreaMapWithTownBorderDeaasTemplate);
