import { action, observable } from "mobx";
import RequestsResponse from "../interfaces/RequestsResponse";
import _ from "lodash";
import axios from 'axios';
import { RequestModel } from "../models/RequestModel";

export class RequestsStore {
  requests = observable<RequestModel>([]);

  @observable
  centerLat: number = 0;

  @observable
  centerLng: number = 0;

  @observable
  latestLoadedAt: Date = new Date();

  intervalTimer: any | null = null;

  deliveredTimeBeforeMinutes: number = 0

  loading: boolean = false

  @action
  public setCenter({ lat, lng }) {
    this.centerLat = lat;
    this.centerLng = lng;
  }

  @action
  public subscribeRequests(ne, se, sw, nw) {
    this.loadRequests(ne, se, sw, nw);

    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
    }
    this.intervalTimer = setInterval(this.loadRequests.bind(this, ne, se, sw, nw), 30000);
  }

  @action
  private async loadRequests(ne, se, sw, nw) {
    this.loading = true
    const response = await axios.get('/api/requests', {
      params: {
        // ここにクエリパラメータを指定する
        lat: String(this.centerLat),
        lng: String(this.centerLng),
        neLng: String(ne.lng),
        neLat: String(ne.lat),
        seLng: String(se.lng),
        seLat: String(se.lat),
        swLng: String(sw.lng),
        swLat: String(sw.lat),
        nwLng: String(nw.lng),
        nwLat: String(nw.lat),
        limit_time_value: this.deliveredTimeBeforeMinutes !== 0 ? String(this.deliveredTimeBeforeMinutes): undefined,
        limit_time_unit: this.deliveredTimeBeforeMinutes !== 0  ? "MINUTE": undefined
      }
    });

    this.loading = false

    const results = response.data;

    const requests = _.map(results, (result: RequestsResponse) => {
      return new RequestModel(result)
    });
    this.replaceRequests(requests);

    this.latestLoadedAt = new Date()
  }

  @action
  private replaceRequests(requests) {
    this.requests.replace(requests)
  }


  @action
  public setDeliveredTimeBeforeMinutes(minutes: number ) {
    this.deliveredTimeBeforeMinutes　= minutes
  }
}

const singleton = new RequestsStore();
export default singleton
