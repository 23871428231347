import React from "react";
import {Bar} from "react-chartjs-2";
import ServiceArea from "../interfaces/ServiceArea";
import ServiceAreaTimeline from "../interfaces/ServiceAreaTimeline";

interface Props {
  serviceArea: ServiceArea,
  serviceAreaTimelines: ServiceAreaTimeline[]
}

interface State {
}

export default class ServiceAreaCapacityChart extends React.Component<Props, State> {
  barChart: Bar;

  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
    };
  }

  render() {
    const data = {
      labels: this.getLabels(),
      datasets: [
        {
          type: 'line',
          // 説明を容易にするためenterpriseではcapacityをratioと表記
          label: 'ratio(配達依頼数÷配達員数)',
          yAxisID: 'axisCapacity',
          fill: false,
          data: this.getCapacities(),
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
        },
        {
          type: 'bar',
          label: '店舗到着遅延分数',
          yAxisID: 'axisRequiredMinute',
          data: this.getRequiredMinute(),
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
        },
      ],
    };
    const options = {
      scales: {
        yAxes: [
          {
            // 表示のされ方が微妙なので、pluginsでいじってみたが、いったんこっち
            id: 'axisCapacity',
            type: 'linear',
            position: 'left',
            scaleLabel: {              //軸ラベル設定
              display: true,          //表示設定
              labelString: 'ratio',  //ラベル。enterpriseではcapacityをratioと表記
              fontSize: 14               //フォントサイズ
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 6
            }
          },
          {
            id: 'axisRequiredMinute',
            position: 'right',
            scaleLabel: {              //軸ラベル設定
              display: true,          //表示設定
              labelString: '店舗到着遅延分数',  //ラベル
              fontSize: 14               //フォントサイズ
            },
            ticks: {
              beginAtZero: true,
              min: 0,
              max: 60
            }
          }
        ]
      },
      legend: {
        display: true, // 複数表示なので必要
        onHover: function(e) {
          e.target.style.cursor = 'pointer';
        }
      },
      hover: {
        onHover: function(e) {
          const point = this.getElementAtEvent(e);
          if (point.length) e.target.style.cursor = 'pointer';
          else e.target.style.cursor = 'default';
        }
      },
      maintainAspectRatio: false
    };
    return (
      <div className={"service-area-chart-container"}>
        <div className={"service-area-capacity-chart-title"}>{this.props.serviceArea.name}</div>
        <Bar data={data} options={options} onElementsClick={this.handleClick}
             ref={(ref) => this.barChart = ref}
             height={180}
        />
      </div>
    );
  }

  // 配列にはlineとbarのChartElementが0 1 で２個入っている
  handleClick = async (e: any) => { // ChartElementがimportできないので、、、
    if (e === null || e === undefined) {
        return;
    }
    if (!Array.isArray(e) || e.length === 0) {
        return;
    }
    if (e[0] === null || e[0] === undefined || !e[0].hasOwnProperty('_index')) {
      return;
    }
    const timeline: ServiceAreaTimeline = this.props.serviceAreaTimelines[e[0]._index];
    // 画面遷移の良い方法が思いつかないので、相対パスのlocation.hrefでの遷移とする
    location.href = 'service_areas/' + timeline.service_area_id
      + '/service_area_timelines/' + timeline.id + '/edit'
  }

  // x軸は同じ時間を使用
  private getLabels(): string[] {
    return this.props.serviceAreaTimelines.map(
      (timeline: ServiceAreaTimeline) => {
        const date: Date = new Date(timeline.sdate);
        let minutes: string = String(date.getMinutes());
        if (minutes.length === 1) {
          minutes = '0' + minutes;
        }
        return date.getHours() + ':' + minutes;
      }
    );
  }

  private getCapacities(): number[] {
    // capacityは小数点第２位を切り捨てて表示
    return this.props.serviceAreaTimelines.map(
      (timeline: ServiceAreaTimeline) => {
        return Math.round(+timeline.capacity * 10) / 10;
      }
    );
  }

  private getRequiredMinute(): number[] {
    return this.props.serviceAreaTimelines.map(
      (timeline: ServiceAreaTimeline) => {
        return timeline.required_minute;
      }
    );
  }
}

