import { action, observable, set } from "mobx";
import CurrentLocationsResponse from "../interfaces/CurrentLocationsResponse";

export class CurrentLocationWithCarryStaffModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;

  moveMethod: number;

  @observable lat: number;

  @observable lng: number;

  @observable status: number;

  @observable inProgressRequestCount: number;

  @observable mayStayForAWhile: boolean;

  carryStaffId: number;

  visible: boolean;

  carryStaffAvoidAssign: boolean;

  constructor(response: CurrentLocationsResponse) {
    this.id = response.id;
    this.key = response.id;
    this.name = response.carry_staff_name;
    this.moveMethod = response.carry_staff_move_method;
    this.lat = Number(response.lat);
    this.lng = Number(response.lng);
    this.inProgressRequestCount = Number(response.in_progress_request_count);
    this.carryStaffId = Number(response.carry_staff_id)
    this.mayStayForAWhile = response.carry_staff_may_stay_for_a_while;
    this.visible = true;
    this.carryStaffAvoidAssign = response.carry_staff_avoid_assign;
  };
}
