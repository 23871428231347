import React from "react";
import carryStaffLocationsStore from "../stores/CarryStaffLocationsStore";
import {observer} from "mobx-react";
import {CurrentLocationWithCarryStaffModel} from "../models/CurrentLocationWithCarryStaffModel";

interface Props {}
interface State {}

class CarryStaffOverlookDriverList extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    handleClickCheckbox(carryStaff: CurrentLocationWithCarryStaffModel) {
        carryStaffLocationsStore.toggleCarryStaffVisible(carryStaff)
    }
    render() {
        return(
            <div>
                <h4 className="mr-2">ドライバー一覧</h4>
                <ul className="list-group">
                {
                    carryStaffLocationsStore.locations.map((carryStaff) => {
                        return(
                            <li className="list-group-item" key={carryStaff.id}>
                                <input type="checkbox" defaultChecked={carryStaff.visible} className="mr-1" onClick={() => {this.handleClickCheckbox(carryStaff)}}/>
                                { carryStaff.name }
                            </li>
                        )
                    })
                }
                </ul>
            </div>
        )
    }
}

export default observer(CarryStaffOverlookDriverList)