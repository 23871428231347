import React from "react";

interface Props {
  mapApi: any,
  map: any,
  color?: string,
  center: {
    lat: number,
    lng: number,
  },
  range: number
}

class CircleArea extends React.Component<Props> {
  // mapapiを使って描画する場合、reactのlife cycleとタイミングが合わない
  // そのため、state相当のlineを自分で管理する
  circle: any = null;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    // propsが更新されば場合は既存のlineをmap上から削除
    if (this.circle) {
      this.circle.setMap(null)
    }
  }

  componentWillUnmount() {
    // コンポーネントが消されたとき、ラインが地図上からも表示されないようにする
    if (this.circle) {
      this.circle.setMap(null)
    }
  }

  render() {
    this.renderCircle()
    return null
  }

  private renderCircle() {
    const circle = new this.props.mapApi.Circle(
      {
        strokeColor: this.props.color ? this.props.color : '#FF0000',
        strokeOpacity: 0.7,
        strokeWeight: 2,
        fillColor: this.props.color ? this.props.color : '#FF0000',
        fillOpacity: 0.30,
        map: this.props.map,
        center: this.props.center,
        radius: this.props.range
      }
    );

    this.circle = circle;
  }
}

export default CircleArea;
