import React, {useEffect, useState} from "react";
import {MapApi} from "../index";
interface Props extends  google.maps.MarkerOptions{
    options: google.maps.MarkerOptions
    mapApi: MapApi
    onDragend?: (e: google.maps.MouseEvent) => void
    eventKey?: string | number
}
export function Marker(props: Props) {
    const {options, mapApi, onDragend, eventKey} = props
    const [marker, setMarker] = useState<google.maps.Marker>();
    const [dragendEvent, setDragendEvent] = useState<any>()
    useEffect(() => {

        if (!marker) {
            const m = new mapApi.maps.Marker()
            const dragend = m.addListener("dragend", (e) => onDragend(e))
            setDragendEvent(dragend)
            setMarker(m);
        }

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [marker]);

    useEffect(() => {
        if (marker) {
            marker.setOptions(options);
            marker.setMap(mapApi.map)
        }
    }, [options]);


    // イベントを更新するときはpropsのeventKeyを更新する
    useEffect(() => {
        if(!marker) return
        if(dragendEvent )  dragendEvent.remove()
        const dragend = marker.addListener("dragend", (e) => onDragend(e))
        setDragendEvent(dragend)
    }, [eventKey]);

    return <span style={{display: "none"}}/>
};