import React from "react";
import { RequestModel } from "../models/RequestModel";

interface Props {
  mapApi: any,
  map: any,
  color?: string,
  isFirstRequest?: boolean,
  request: RequestModel
}

class RequestPolyline extends React.Component<Props> {
  // mapapiを使って描画する場合、reactのlife cycleとタイミングが合わない
  // そのため、state相当のlineを自分で管理する
  line1: any = null;
  line2: any = null;

  constructor(props: Props) {
    super(props);
  }

  componentWillUpdate() {
    // propsが更新されば場合は既存のlineをmap上から削除
    if (this.line1) {
      this.line1.setMap(null)
    }
    if (this.line2) {
      this.line2.setMap(null)
    }
  }

  componentWillUnmount() {
    // コンポーネントが消されたとき、ラインが地図上からも表示されないようにする
    if (this.line1) {
      this.line1.setMap(null)
    }
    if (this.line2) {
      this.line2.setMap(null)
    }
  }

  render() {
    if (this.props.request.isEarlyStage()) {
      if (this.props.request.carryStaff && this.props.isFirstRequest) {
        this.renderEarlyStage();
      } else {
        this.renderNoAssignStage();
      }
    } else if (this.props.request.isDeliveryStage()) {
      if (this.props.isFirstRequest){
        this.renderDeliveryStage();
      }else{
        this.renderNoAssignStage();
      }
    } else {
      this.renderDoneStage();
    }

    return null
  }

  private renderEarlyStage() {
    const { sender, receiver, carryStaff } = this.props.request;

    // 配達スタッフの緯度経度がまだ登録されていない場合
    if (!carryStaff.lat) {
      this.renderNoAssignStage();
      return
    }

    const toSenderLine = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderToStorePolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(carryStaff.lat, carryStaff.lng),
            new this.props.mapApi.LatLng(sender.lat, sender.lng),
          ],
          map: this.props.map,
          icons: [
            {
              icon: this.renderLineSymbol(),
              offset: '25%'
            }
          ]
        }
      )
    );

    const toReceiverLine = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderDotPolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(sender.lat, sender.lng),
            new this.props.mapApi.LatLng(receiver.lat, receiver.lng),
          ],
          map: this.props.map
        }
      )
    );

    this.line1 = toSenderLine;
    this.line2 = toReceiverLine;
  }

  private renderDeliveryStage() {
    const { sender, receiver, carryStaff } = this.props.request;

    // 配達スタッフの緯度経度がまだ登録されていない場合
    if (!carryStaff || !carryStaff.lat) {
      this.renderNoAssignStage();
      return
    }

    const toSenderLine = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderDotPolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(sender.lat, sender.lng),
            new this.props.mapApi.LatLng(carryStaff.lat, carryStaff.lng),
          ],
          map: this.props.map
        }
      )
    );

    const toReceiverLine = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderToCustomerPolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(carryStaff.lat, carryStaff.lng),
            new this.props.mapApi.LatLng(receiver.lat, receiver.lng),
          ],
          map: this.props.map,
          icons: [
            {
              icon: this.renderLineSymbol(),
              offset: '25%'
            }
          ]
        }
      )
    );

    this.line1 = toSenderLine;
    this.line2 = toReceiverLine;
  }

  private renderNoAssignStage() {
    const { sender, receiver } = this.props.request;
    this.line1 = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderDotPolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(sender.lat, sender.lng),
            new this.props.mapApi.LatLng(receiver.lat, receiver.lng),
          ],
          map: this.props.map
        }
      )
    );
    this.line2 = null;
  }

  private renderDoneStage() {
    const { sender, receiver, carryStaff } = this.props.request;
    this.line1 = new this.props.mapApi.Polyline(
      Object.assign({},
        this.renderDotPolyline(),
        {
          path: [
            new this.props.mapApi.LatLng(sender.lat, sender.lng),
            new this.props.mapApi.LatLng(receiver.lat, receiver.lng),
          ],
          map: this.props.map,
        }
      )
    );
    this.line2 = null;
  }


  renderPolyline() {
    return {
      geodesic: true,
      strokeColor: 'rgba(47,149,220, 0.9)',
      strokeOpacity: 1,
      strokeWeight: 3,
    }
  }

  renderDotPolyline() {
    return {
      geodesic: true,
      strokeColor: 'rgba(47,149,220, 0.9)',
      strokeOpacity: 0,
      icons: [
        this.dotLineIcon()
      ]
    }
  }

  renderToStorePolyline() {
    return {
      geodesic: true,
      strokeColor: 'rgba(255, 193, 7, 0.8)',
      strokeOpacity: 1,
      strokeWeight: 3,
    }
  }

  renderToCustomerPolyline() {
    return {
      geodesic: true,
      strokeColor: 'rgba(255, 193, 7, 0.8)',
      strokeOpacity: 1,
      strokeWeight: 3,
    }
  }

  private renderLineSymbol() {
    return {
      anchor: this.props.mapApi.Point(30, 0),
      path: this.props.mapApi.SymbolPath.FORWARD_CLOSED_ARROW,
      fillOpacity: 1,
      scale: 4
    };
  }

  private dotLineIcon() {
    return {
      icon: {
        path: 'M 0,-1 0,1',
        strokeOpacity: 0.8,
        scale: 3
      },
      offset: '0',
      repeat: '20px'
    }
  };

}

export default RequestPolyline;
