import React from "react";
import {observer} from "mobx-react";
import spotRemarkMapStore from '../stores/SpotRemarkMapStore';
import {NewMarker} from '../stores/SpotRemarkMapStore';
import GoogleMapReact, {ClickEventValue} from 'google-map-react';
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";
import CircleArea from "../components/CircleArea";
import MarkerColors from "../constants/MarkerColors";
import WktMultiPolygon from "../components/WktMultiPolygon";

import SpotRemarkTempMarker from "./SpotRemarkTempMarker";

import SpotRemark from "../interfaces/SpotRemark";

import $ from 'jquery';


declare var gon: any;

interface Props {
  spotRemark: SpotRemark,
  editable: boolean, // 詳細画面(show)からくる場合はfalse
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean,
  centerLat: number;
  centerLng: number;
  draggable: boolean;
  address: string;
  defaultZoom: number;
  useDestPoint: number;
}

class SpotRemarkMap extends React.Component<Props, State> {

  rootDiv: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,

      centerLat: this.isEditingSpotRemark() ? Number(props.spotRemark.center_lat) : this.getDefaultCenterLat(),
      centerLng: this.isEditingSpotRemark() ? Number(props.spotRemark.center_lng) : this.getDefaultCenterLng(),

      defaultZoom: this.getDefaultZoom(),

      draggable: true, // マーカーのドラッグ中はfalseにする
      address: '',
      useDestPoint: (this.props.spotRemark) ? this.props.spotRemark.use_dest_point : 0,

    };
  }

  componentDidMount(): void {
    $('#spot_remark_use_dest_point_0').off('click');
    $('#spot_remark_use_dest_point_0').on('click', (e) => {
      if (this.state.useDestPoint === 1) {
        this.setState({useDestPoint: 0});
        spotRemarkMapStore.setDestMarkerVisible(false);
      }
    });

    $('#spot_remark_use_dest_point_1').off('click');
    $('#spot_remark_use_dest_point_1').on('click', (e) => {
      if (this.state.useDestPoint === 0) {
        this.setState({useDestPoint: 1});
        // 手動で移動した場合でも初期位置をいい感じに出したいので、重心計算とする
        const destPosition: { lat: number, lng: number } = this.getCurrentDefaultDestPosition();
        spotRemarkMapStore.changeDestMarkerPosition(destPosition.lat, destPosition.lng);
        spotRemarkMapStore.setDestMarkerVisible(true);
      }
    });

    const { spotRemark } = this.props;
    const wkt: string | null = spotRemark.within_area_wkt;
    const markersFromWkt = wkt ? this.getMarkersFromWkt(wkt) : [];

    const destMarker = {
      lat: MapAttributes.SHIBUYA_STATION_LATITUDE,
      lng: MapAttributes.SHIBUYA_STATION_LONGITUDE,
      isDest: true
    };
    if (spotRemark.use_dest_point === 1) {
      destMarker.lat = spotRemark.dest_lat;
      destMarker.lng = spotRemark.dest_lng;
    } else if (wkt) {
      // 保存済みのWKTがあれば、その中心とする
      destMarker.lat = spotRemark.center_lat;
      destMarker.lng = spotRemark.center_lng;
    } else if (!_.isEmpty(this.state.map)) {
      // 中心マーカ―はあとで表示時に必ず上書きされるのでなんでも良いがいったん中心を基本とする
      destMarker.lat = Number(this.state.map.center.lat());
      destMarker.lng = Number(this.state.map.center.lng());
    }
    spotRemarkMapStore.setInitialNewMarkers([...markersFromWkt, destMarker]);
    spotRemarkMapStore.setDestMarkerVisible(this.props.spotRemark.use_dest_point === 1);
  }

  private getCurrentDefaultDestPosition(): { lat: number, lng: number } {
    const newMarkers: { lat: number, lng: number, isDest: boolean }[] = [];
    _.map(spotRemarkMapStore.visibleNewMarkers.slice(), (visibleNewMarker) => {
      // destPointは見えてない場合がある
      if (!visibleNewMarker.visible || visibleNewMarker.isDest) {
        return
      }
      newMarkers.push(visibleNewMarker);
    })
    if (newMarkers.length < 3) {
      return {
        lat: Number(this.state.map.center.lat()),
        lng: Number(this.state.map.center.lng()),
      };
    }
    return this.calcCenterLatLng(newMarkers);
  }

  private getMarkersFromWkt = (wkt: string): { lat: number, lng: number, isDest: boolean }[] => {
    wkt = wkt.replace(/MultiPolygon\(\(\(/, '')
      .replace(/\)\)\)/, '');
    const spWkt = _(wkt).split(',').map((item)=> {
      const split = _.trim(item).split(' ')
      return {
        lat: +split[1],
        lng: +split[0],
        isDest: false
      }
    }).value();
    
    //下位互換、一部過去データは始点と終点が異なるデータが入っているケースがある
    if (spWkt[0].lat !== spWkt[spWkt.length-1].lat || spWkt[0].lng !== spWkt[spWkt.length-1].lng) {
      return spWkt
    }

    return spWkt.slice(0 ,spWkt.length -1)
  }

  // Rails側のフォームの値で、中心緯度、中心経度、範囲指定(wkt形式)の部分を更新する
  // spot_remark_center_lat spot_remark_center_lng spot_remark_within_area_wkt
  private refreshRailsArea() {
    const centerLatEle = $('#spot_remark_center_lat');
    const centerLngEle = $('#spot_remark_center_lng');
    const wktEle = $('#spot_remark_within_area_wkt');
    const newMarkers: { lat: number, lng: number, isDest: boolean }[] = [];
    _.map(spotRemarkMapStore.visibleNewMarkers.slice(), (visibleNewMarker) => {
      // destPointは見えてない場合がある
      if (!visibleNewMarker.visible || visibleNewMarker.isDest) {
        return
      }
      newMarkers.push(visibleNewMarker);
    })

    // destLat系は先にやる　null(未設定)がありえる
    if (this.state.useDestPoint === 1) {
      const destMarker = _.find(spotRemarkMapStore.visibleNewMarkers.slice(), (newMarker) => {
        return newMarker.isDest;
      })
      // console.log(destMarker);
      if (destMarker) { // タイミング問題で存在しない場合がある
        $('#spot_remark_dest_lat').val(Number(destMarker.lat).toFixed(6));
        $('#spot_remark_dest_lng').val(Number(destMarker.lng).toFixed(6));
      }
    } else {
      $('#spot_remark_dest_lat').val('');
      $('#spot_remark_dest_lng').val('');
    }

    if (newMarkers.length < 3) { // ３点ないとエリアじゃないので保存時にエラーになる
      centerLatEle.val('');
      centerLngEle.val('');
      wktEle.val('');
      return;
    }

    const centerLatLng: { lat: number, lng: number } = this.calcCenterLatLng(newMarkers);
    // console.log(newMarkers);
    // console.log(centerLatLng);
    centerLatEle.val(Number(centerLatLng.lat).toFixed(6));
    centerLngEle.val(Number(centerLatLng.lng).toFixed(6));

    wktEle.val(this.createWktText(newMarkers));

  }

  private calcCenterLatLng = (newMarkers: { lat: number, lng: number, isDest: boolean }[]): { lat: number, lng: number } => {
    let maxLat: number = newMarkers[0].lat;
    let minLat: number = newMarkers[0].lat;
    let maxLng: number = newMarkers[0].lng;
    let minLng: number = newMarkers[0].lng;

    // 必ず存在するはずなので後続処理はいらないはずだが、、、
    _.map(newMarkers.slice(), (newMarker) => {
      if (newMarker.isDest) {
        return; // continue
      }
      if (newMarker.lat > maxLat) {
        maxLat = newMarker.lat;
      }
      if (newMarker.lng > maxLng) {
        maxLng = newMarker.lng;
      }
      if (newMarker.lat < minLat) {
        minLat = newMarker.lat;
      }
      if (newMarker.lng < minLng) {
        minLng = newMarker.lng;
      }
    })
    return {lat: (maxLat + minLat) / 2, lng: (maxLng + minLng) / 2}
  }

  render() {
    this.refreshRailsArea();

    return (
      <div>
        {this.renderSearchCond()}

        <div className={'area-topic-map-container'} ref={(node) => this.rootDiv = node}>
          <GoogleMapReact
            draggable={this.state.draggable}
            onClick={this.onClick}
            onChange={(e) => {
              this.onChangeMap(e)
            }}
            onChildMouseDown={this.onChildMouseDown}
            onChildMouseUp={this.onChildMouseUp}
            onChildMouseMove={this.onChildMouseMove}
            bootstrapURLKeys={{
              key: gon.google_api_key
            }}
            defaultCenter={{
              lat: this.state.centerLat,
              lng: this.state.centerLng
            }}
            defaultZoom={this.state.defaultZoom}
            center={{
              lat: this.state.centerLat,
              lng: this.state.centerLng
            }}
            resetBoundsOnResize={true}
            hoverDistance={MapAttributes.K_SIZE / 2}
            onGoogleApiLoaded={
              ({map, maps}) => {
                spotRemarkMapStore.setMap(map);
                this.setState({
                  map: map,
                  mapApi: maps,
                  mapLoaded: true
                });
              }
            }
          >
            {this.createNewMarkers()}
            {this.createNewMarkersPolygon()}
          </GoogleMapReact>
        </div>
      </div>
    );
  }

  private renderSearchCond() {
    if (!this.props.editable) {
      return null;
    }
    return (
      <div className="form-group form-inline mb-2">
        <input
          type="text" className="form-control input-overlook-address"
          value={this.state.address}
          placeholder={"住所（一部分でも可）"}
          onChange={(event) => {
            this.setState({
              address: event.target.value
            })
          }}
        />
        <button className="btn btn-info ml-2" onClick={() => {
          spotRemarkMapStore.moveCenter(this.state.address)
        }}>中心地点を移動
        </button>

      </div>
    )
  }

  private isEditingSpotRemark = (): boolean => {
    if (this.props.spotRemark.id == null) {//新規作成の場合（LatLngだと初期値が入ってしまって判定できない）
      return false;
    }
    return true;
  }

  private onClick = (value: ClickEventValue): any => {
    if (!this.props.editable) {
      return;
    }
    spotRemarkMapStore.addNewMarkerWithLatLng(value.lat, value.lng, false);
    // // 基本的に存在しているはず 存在していなければ、３角形作成時点で自動追加
    // if (!spotRemarkMapStore.hasCenterMarker() && spotRemarkMapStore.visibleNewMarkers.length === 3) {
    //   const center = this.autoCalcCenterLatLng(spotRemarkMapStore.visibleNewMarkers);
    //   spotRemarkMapStore.addNewMarkerWithLatLng(center.lat, center.lng, true);
    // }

  }

  private onChangeMap(e) {
    if (this.isEditingSpotRemark()) {//編集時は場所を記憶しない
      this.setCurrentLatLngZoomToLocalStrage(e.center.lat, e.center.lng, e.zoom);
    }
  }

  // このへんを参考に
  // https://jsbin.com/molerasebo/2/edit?js,console,output
  private onChildMouseDown = (childKey: any, childProps: any, mouse: any): void => {
    if (!this.props.editable) {
      return;
    }
    this.setState({draggable: false});
    spotRemarkMapStore.changeMarkerPositionById(childKey, mouse.lat, mouse.lng);
  }

  private onChildMouseUp = (childKey: any, childProps: any, mouse: any): void => {
    if (!this.props.editable) {
      return;
    }
    this.setState({draggable: true});
  }

  private onChildMouseMove = (childKey: any, childProps: any, mouse: any): void => {
    if (!this.props.editable) {
      return;
    }
    this.setState({draggable: false});
    spotRemarkMapStore.changeMarkerPositionById(childKey, mouse.lat, mouse.lng);
  }

  private createNewMarkers() {
    return _.map(spotRemarkMapStore.visibleNewMarkers.slice(), (visibleNewMarker) => {
      if (!visibleNewMarker.visible) {
        return
      }
      return (
        <SpotRemarkTempMarker
          key={visibleNewMarker.id}
          lat={visibleNewMarker.lat}
          lng={visibleNewMarker.lng}
          isCenter={visibleNewMarker.isDest}
          editable={this.props.editable}
          markerKey={visibleNewMarker.id}
          calcMenuPosition={this.calcMenuPosition}
          onClickDelete={this.onClickDelete}
        />
      )
    })
  }

  private calcMenuPosition = (clientX: number, clientY: number): { x: number, y: number } => {
    return {
      x: clientX - this.rootDiv.clientLeft,
      y: clientY - this.rootDiv.clientTop,
    }
  }

  private onClickDelete = (markerKey: number): void => {
    spotRemarkMapStore.deleteMarker(markerKey);
  }

  private createNewMarkersPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null
    }
    const newMarkers: NewMarker[] = [];
    _.map(spotRemarkMapStore.visibleNewMarkers.slice(), (visibleNewMarker) => {
      if (!visibleNewMarker.visible) {
        return;
      }
      newMarkers.push(visibleNewMarker);
    })

    if (newMarkers.length === 0) {
      return;
    }

    return (
      <WktMultiPolygon
        key={"select-area-" + Math.random()}
        map={this.state.map}
        mapApi={this.state.mapApi}
        wktText={this.createWktText(newMarkers)}
      />
    )
  }

  private createWktText(newMarkers: { lat: number, lng: number, isDest: boolean }[]): string {
    const geoMarkers = _.filter(newMarkers, (newMarker) => {
      return (newMarker.isDest == false)
    })
    //ポリゴンになるように最初の要素を末尾に加える
    if (geoMarkers.length !== 0) geoMarkers.push(geoMarkers[0]);
    const attributes = _(geoMarkers).map((marker) => {
      return marker.lng + " " + marker.lat
    }).join(",")

    return `MultiPolygon(((${attributes})))`
  }

  private setCurrentLatLngZoomToLocalStrage(lat, lng, zoom) {
    localStorage.setItem(this.getDefaultCenterLatKey(), lat);
    localStorage.setItem(this.getDefaultCenterLngKey(), lng);
    localStorage.setItem(this.getDefaultZoomKey(), zoom);
  }

  private getDefaultCenterLatKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_SPOTREMARKMAP_CENTER_LAT + gon.current_user_id;
  }

  private getDefaultCenterLngKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_SPOTREMARKMAP_CENTER_LNG + gon.current_user_id;
  }

  private getDefaultZoomKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_SPOTREMARKMAP_ZOOM + gon.current_user_id;
  }

  private getDefaultCenterLat(): number {
    let lat = Number(localStorage.getItem(this.getDefaultCenterLatKey()));
    if (lat <= 1) {
      lat = MapAttributes.SHIBUYA_STATION_LATITUDE;
    }
    return lat;
  }

  private getDefaultCenterLng(): number {
    let lng = Number(localStorage.getItem(this.getDefaultCenterLngKey()));
    if (lng <= 1) {
      lng = MapAttributes.SHIBUYA_STATION_LONGITUDE;
    }
    return lng;
  }

  private getDefaultZoom(): number {
    let zoom: number = Number(localStorage.getItem(this.getDefaultZoomKey()));
    if (zoom <= 1) {
      zoom = MapAttributes.SPOTREMARK_DEFAULT_ZOOM;
    }
    return zoom;
  }
}

export default observer(SpotRemarkMap);
