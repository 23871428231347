import { action, observable, set } from "mobx";
import OfficesResponse from "../interfaces/OfficesResponse";

export class OfficeModel{
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;
  
  zipcode: string;
  
  state: string;
  
  city: string;
  
  address1: string;
  
  address2: string;
  
  lat: number;
  
  lng: number;
  
  constructor(response: OfficesResponse) {
    this.id = response.id;
    this.key = response.id;
    
    this.name = response.name;
    this.zipcode = response.zipcode;
    this.state = response.state;
    this.city = response.city;
    this.address1 = response.address1;
    this.address2 = response.address2;
    
    this.lat = Number(response.lat);
    this.lng = Number(response.lng);
  }
}
