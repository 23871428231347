import React, {useCallback, useEffect, useState} from "react";
import {TownBorder} from "../../../interfaces/TownBorder";
import {booleanPointInPolygon, multiPolygon, point, polygon} from "@turf/turf";
import type {MapApi} from "../../ui/GmapsUI";
// @ts-ignore
import {parse} from "wkt"
import prefectures from "./../../../constants/Prefectures"
import {Coords} from "google-map-react";

interface FilteredTownBorder extends TownBorder {
    inArea: boolean
    selected: boolean
}

interface Props {
    mapApi?: MapApi
    wkt: string
    selectedFeature: any | undefined
    changeCenter: (coords: Coords) => void
    onSubmitSearch: (query: any, pref: string, page?: number) => Promise<TownBorder[]>
    onClickTown: (event: any) => void
}

export default function MAPDrawerContent(props: Props) {
    const {wkt, mapApi, onClickTown, onSubmitSearch, selectedFeature, changeCenter} = props
    const [searchKeyword, setSearchKeyword] = useState("")
    const [filteredTowns, setFilteredTowns] = useState<FilteredTownBorder[]>([])
    const [searchResultTowns, setSearchResultTowns] = useState<TownBorder[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [page, setPage] = useState(1)
    const [hasNext, setHasNext] = useState(false)
    const [pref, setPref] = useState<string>()

    useEffect(() => {
        const filteredTowns: FilteredTownBorder[] = searchResultTowns.map((town) => {
            return {
                ...town,
                inArea: isOverlap(wkt, town.center),
                selected: false
            }
        })
        setFilteredTowns(filteredTowns)
    }, [searchResultTowns, wkt])

    const handleSubmitSearch = (e: any) => {
        setIsLoading(true)
        setSearchResultTowns([])
        onSubmitSearch(searchKeyword, pref).then((res) => {

            setIsLoading(false)
            setSearchResultTowns(res)
            setPage(1)
            if (res.length && res.length >= 50) {
                setHasNext(true)
            } else {
                setHasNext(false)
            }
        }).finally(() => setIsLoading(false))
        e.preventDefault()
    }

    const handleClickLoadNext = useCallback(() => {
        setIsLoading(true)
        onSubmitSearch(searchKeyword, pref, page + 1).then((res) => {
            setIsLoading(false)
            setSearchResultTowns([...searchResultTowns, ...res])
            setPage(page + 1)
            if (res.length) {
                setHasNext(true)
            } else {
                setHasNext(false)
            }
        }).finally(() => setIsLoading(false))
    }, [searchResultTowns, page, pref])

    const isSelected = useCallback((town: TownBorder) => {
        if (selectedFeature) {
            return selectedFeature.features.find((feat: any) => {
                return feat.properties.id === town.id
            })
        } else {
            return false
        }
    }, [wkt, selectedFeature])

    const panToCenterByPointWkt = (wkt: string) => {
        const point = parse(wkt)
        mapApi?.map.panTo(new mapApi.maps.LatLng({lat: point.coordinates[1], lng: point.coordinates[0]}))
        changeCenter({lat: point.coordinates[1], lng: point.coordinates[0]})
    }
    return (
        <div className="py-3 px-3" style={{marginBottom: 32}}>
            {
                mapApi &&
                <>
                    <form
                        className="mb-2"
                        onSubmit={handleSubmitSearch}
                    >
                        <label className="text-left font-weight-bold d-block">都道府県</label>
                        <select
                            className="form-control  form-control-sm mb-2"
                            defaultValue={pref}
                            onChange={(e) => setPref(e.target.value as string)}
                        >
                            <option> (未選択)</option>
                            {
                                prefectures.map((pref) => {
                                    return (
                                        <option value={pref.name} key={pref.code}>{pref.name}</option>
                                    )
                                })
                            }
                        </select>
                        <label className="text-left font-weight-bold d-block">市区町村・町域</label>


                        <div className="d-flex" style={{gap: 1}}>
                            <input
                                type="text"
                                className="form-control form-control-sm mr-1"
                                placeholder="例）渋谷区恵比寿"
                                onChange={(e) => {
                                    setSearchKeyword(e.target.value)
                                }}/>
                            <button type="submit" className="btn btn-light btn-sm block"><i
                                className="fas fa-search"></i></button>
                        </div>
                    </form>

                    {
                        filteredTowns.map((town) => {
                            return (
                                <div style={{
                                    borderBottom: "1px solid #ccc",
                                    textAlign: "left",
                                    padding: "4px 0",
                                    display: "flex",
                                    cursor: "pointer"
                                }}
                                     key={town.id}
                                >
                                    <div
                                        onClick={() => {
                                            panToCenterByPointWkt(town.center)
                                        }}>

                                        {town.inArea ? (
                                            <i className="fas fa-check-circle mr-1 " style={{color: "#666"}}/>
                                        ) : (
                                            <i className="fas fa-circle mr-1" style={{color: "#f1f1f1"}}/>
                                        )}
                                        <UnderlineAtHoverText>
                                            {town.pref}{town.city}{town.town}
                                        </UnderlineAtHoverText>

                                    </div>
                                    <button
                                        style={{
                                            fontSize: 11,
                                            border: isSelected(town) ? "solid 1px #666" : "solid 1px #ccc",
                                            marginLeft: "auto",
                                            backgroundColor: isSelected(town) ? "#999" : "#f1f1f1",
                                            color: isSelected(town) ? "#fff" : "#333",
                                            borderRadius: 4,
                                            whiteSpace: "nowrap",
                                        }}
                                        onClick={() => {
                                            onClickTown(town)
                                        }}
                                    >
                                        選択
                                    </button>
                                </div>
                            )
                        })
                    }
                    {

                        isLoading &&
                        <div
                            className="my-2"
                            style={{
                                fontSize: 12,
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                border: "none",
                                backgroundColor: "#ffff",
                                color: "#666"
                            }}
                        >
                            <div className="spinner-border spinner-border-sm mr-2" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            読み込み中...</div>
                    }
                    {
                        !isLoading && hasNext &&
                        <button
                            className="my-2"
                            style={{
                                fontSize: 12,
                                display: "block",
                                width: "100%",
                                border: "solid 1px #ccc",
                                backgroundColor: "#f1f1f1",
                                color: "#666"
                            }}
                            onClick={handleClickLoadNext}
                        >
                            続きを読み込む
                        </button>
                    }
                </>
            }
        </div>
    )
}

const isOverlap = (polygonWkt: string, pointWkt: string) => {
    if (!polygonWkt) return false

    const polyGeoJSON = parse(polygonWkt)
    const pointGeoJSON = parse(pointWkt)
    const po = point(pointGeoJSON.coordinates)

    if (polyGeoJSON.type === "Polygon") {
        const poly = polygon(polyGeoJSON.coordinates)
        return booleanPointInPolygon(po, poly)
    } else {
        const multiPoly = multiPolygon(polyGeoJSON.coordinates)
        return booleanPointInPolygon(po, multiPoly)
    }
}

const UnderlineAtHoverText = ({children}) => {
    const [isHover, setIsHover] = useState(false)

    return (
        <span
            style={{
                textDecoration: isHover ? "underline" : "none"
            }}
            onMouseOver={() => setIsHover(true)}
            onMouseOut={() => setIsHover(false)}

        >{children}</span>
    )
}