import React from "react";
import { Card, Image } from 'react-bootstrap';
import { infoWindowStyle, K_SIZE } from '../styles/CarryStaffMarkerStyles'
import format from 'date-fns/format';

interface Props {
  lat: number,
  lng: number,
  sentAt: Date,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,
  latest: boolean

  // keyを渡すとhoverがうまく判定できないので、key propsは任意にして値をセットしない
  key?: number,
}

interface State {
  showInfoWindow: boolean
}

export default class CarryStaffMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow || this.props.$hover;
    let markerStyle = this.props.latest ? latestMapMarkerStyle : mapMarkerStyle;

    return (
      <div
        style={markerStyle}
        onClick={this.onClickMarker.bind(this)}
      >
        {showInfoWindow && (
          <Card
            style={infoWindowStyle}
          >
            <div
              style={dateHeader}
            >
              ・{format(this.props.sentAt, 'yyyy/MM/dd HH:mm:ss')}
            </div>
            <div
              style={coordText}
            >
              {this.props.lat} {this.props.lng}
            </div>
          </Card>
        )}
      </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({showInfoWindow: !this.state.showInfoWindow})
    event.preventDefault();
    event.stopPropagation();
  }

  private progressText() {
    return `${this.props.lat} ${this.props.lng}`
  }
}


const dateHeader = {
  fontSize: 14,
  fontWeight: 'bold' as 'bold',
};

const coordText = {
  fontSize: 14,
  fontWeight: 'normal' as 'normal',
  marginLeft: 20,
};

const mapMarkerStyle = {
  position: 'absolute' as 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  borderWidth: 1,
  borderColor: 'rgba(47,149,220, 0.9)',
  backgroundColor: 'rgba(47,149,220, 0.5)',
  borderRadius: K_SIZE,
  textAlign: 'left' as 'left',
  fontSize: 12,
  cursor: 'pointer'
};

const latestMapMarkerStyle = {
  ...mapMarkerStyle,
  borderColor: 'rgba(64,220,113, 0.9)',
  backgroundColor: 'rgba(64,220,113, 0.5)',
};
