import React from "react"
import {PinShape, Shape} from "./PinShape"
import {MarkerIcon, SVGIcon} from "./MarkerIcon"
import {PinPulse} from "./PinPulse";
import {PinBadge} from "./PinBadge";
import {PinSpinner} from "./PinSpinner";

interface PulseStyle {
    hexColor?: string,
    durationMs?: number,
    size?: number,
}
interface BadgeStyle {
    color?: string
    iClass?: string
}
interface SpinnerStyle {
    size?: number
    hexColor?: string
    borderWidth?: number
    durationMs?: number
    opacity?: number
    progress?: number
}
interface Props {
    icon: SVGIcon
    size: number
    shape?: Shape
    iconColor?: string
    pinColor?: string
    strokeWidth?: number
    reverse?: boolean
    pulse?: boolean
    spinner?: boolean
    spinnerStyle?: SpinnerStyle
    pulseStyle?: PulseStyle
    badgeText?: string
    badgeStyle?: BadgeStyle
    onClick: (event: any ) => void
}
export class MapMarkerPin extends React.Component<Props> {
    render() {
        const {
            icon,
            shape = "drop",
            iconColor = "#fff",
            pinColor = "#666",
            strokeWidth = 2,
            size,
            pulse,
            pulseStyle,
            badgeText,
            badgeStyle,
            spinner,
            spinnerStyle,
            onClick

        } = this.props
 
        const fill = !this.props.reverse ? pinColor : iconColor
        const stroke = !this.props.reverse ? iconColor : pinColor
        const iconSize = size * 0.7

        return (
            <div
                className="map-marker-pin"
                style={{
                    position: "relative",
                    width: 0,
                    height: 0,
                }}
            >
                {
                    pulse &&
                        <>
                            <PinPulse
                                {...pulseStyle}
                            />
                        </>
                }
                {
                    spinner &&
                        <PinSpinner 
                            {...spinnerStyle}
                        />
                }
                <div
                    className="map-marker-pin--inner"
                    onClick={onClick}
                    style={{
                        width: size,
                        height: size * 1.4, // ピン画像のアスペクト比率 1:1.4
                        transform: `translate(-${size / 2}px, -${(size * 1.4 )  }px)`, // 座標中心に表示を合わせる
                    }}
                >
                    {
                        (badgeText || (badgeStyle && badgeStyle.iClass)) &&
                        <PinBadge {...badgeStyle}>{badgeText}</PinBadge>

                    }

                    <PinShape
                        size={size}
                        fill={fill}
                        shape={shape}
                        stroke={stroke}
                        strokeWidth={strokeWidth}
                    />
                    <div 
                  
                        className="map-marker-pin--icon-wrapper"
                        style={
                            {
                                position: "absolute",
                                bottom: size * 0.55, // アイコンが中心に合うように微調整している
                                right: size * 0.145 // アイコンが中心に合うように微調整している
                            }
                        }>
                            <MarkerIcon icon={icon} size={iconSize} color={stroke}/>
                    </div>

                </div>
            </div>

        )
    }
}
