import MapAttributes from "../constants/MapAttributes";
import Colors from "../constants/MarkerColors";
const K_SIZE = MapAttributes.K_SIZE;

const mapMarkerPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute' as 'absolute',
  width: K_SIZE,
  height: K_SIZE,
  left: -K_SIZE / 2,
  top: -K_SIZE / 2,

  border: `5px solid ${Colors.DEFAULT_COLOR}`,
  borderRadius: K_SIZE,
  backgroundColor: 'white',
  fontSize: 16,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center' as 'center',
  justifyContent:'center' as 'center',
};

const mapMarkerProgressStyleHover = {
  ...mapMarkerPlaceStyle,
  border: `5px solid ${Colors.PROGRESS_COLOR}`,
  color: 'black'
};

const mapMarkerAssignedStyleHover = {
  ...mapMarkerPlaceStyle,
  border: `5px solid ${Colors.ASSIGNED_COLOR}`,
  color: 'black'
};

const infoWindowStyle = {
  position: 'absolute' as 'absolute',
  top: - 8,
  left: K_SIZE,
  width: 240,
  height: 60,
  zIndex: 1,
  boxShadow: "1px 1px 15px rgba(0, 0, 0, 0.25)",
  padding: 8,
};

const infoWindowMayStayStyle = {
  ...infoWindowStyle,
  height: 75
};

export {
  mapMarkerPlaceStyle,
  mapMarkerProgressStyleHover,
  mapMarkerAssignedStyleHover,
  infoWindowMayStayStyle,
  infoWindowStyle,
  K_SIZE
};
