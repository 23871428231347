import React from "react";
import axios from 'axios';
import requestAlertStore from '../stores/RequestAlertStore';
import { csrfToken } from 'rails-ujs';

interface Props {
  label: string,
  request_alert: any,
  confirmed: boolean
}

interface State {
  confirmed: boolean
}


class RequestAlert extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.state = {
      confirmed: props.confirmed
    };
  }

  render() {
    const confirmed = this.state.confirmed
    const badgeStyle = confirmed ? "request-confirmed-alert" : "request-address-inaccurate-label"
    return (
      <div className={"dropdown"}>
        <span
          className={`${badgeStyle} badge dropdown-toggle`}
          data-toggle={"dropdown"}
          >
          {this.props.label}
        </span>
        <ul className={"dropdown-menu"} role={"menu"}>
          { !confirmed && (
            <li role="presentation">
              <a role={"menuitem"} href={"#"}
                 style={{marginLeft: 4}}
                 onClick={this.confirm}
              >
                確認済みにする
              </a>
            </li>
          )}
          { confirmed && (
            <li>
              <a role={"menuitem"} href={"#"}
                 style={{marginLeft: 4}}
                 onClick={this.unconfirm}
              >
                未確認にする
              </a>
            </li>
          )}
        </ul>
      </div>
    )
  }

  confirm = async () => {
    try  {
      await axios.post(`/api/user_request_alerts/${this.props.request_alert.id}/confirm`).then(() => {
        this.setState({confirmed: true});
        requestAlertStore.getAlertNum();
      });
    } catch (e) {

    }
  }

  unconfirm = async () => {
    try  {
      await axios.post(`/api/user_request_alerts/${this.props.request_alert.id}/unconfirm`).then(() => {
        this.setState({confirmed: false});
        requestAlertStore.getAlertNum();
      });
    } catch (e) {

    }
  }
}

export default RequestAlert;
