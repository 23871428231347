import { action, observable } from "mobx";
import Location from "../interfaces/Location";
import LocationsResponse from "../interfaces/LocationsResponse";

// 1人のCarryStaffの位置情報履歴（locations）を保持するストア
// （似た名前のCarryStaffLocationsStoreは、各スタッフの現在位置（current_locations）を保持するストア）
export class CarryStaffLocationHistoriesStore {
  @observable.shallow
  staffLocations: { [carryStaffId: number]: Location[] } = {};

  @action
  public setStaffLocations = (
    carryStaffId: number,
    rawLocations: LocationsResponse[]
  ) => {
    const locations = rawLocations.map((rawLocation) => {
      return {
        id: rawLocation.id,
        lat: Number(rawLocation.lat),
        lng: Number(rawLocation.lng),
        sent_at: new Date(rawLocation.sent_at),
        battery_level: rawLocation.battery_level || -1,
        activity: rawLocation.activity,
      };
    });
    this.staffLocations[carryStaffId] = locations;
  };
}

const singleton = new CarryStaffLocationHistoriesStore();
export default singleton;
