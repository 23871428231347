import React from "react";

interface Props {
    children?: React.ReactNode;
    color?: string
    iClass?: string
}
export class PinBadge extends React.Component<Props> {
    render() {
      
        const {color = "#f78733", iClass, children} = this.props
        return (
            <div
                style={{
                    background: color,
                    color: "#fff",
                    padding: "0 4px",
                    borderRadius: "2em",
                    border: "2px solid #fff",
                    position: "absolute",
                    minHeight: "2em",
                    minWidth: "2em",
                    lineHeight: "1em",
                    fontWeight: "bold",
                    top: "-1.5em",　　// 表示位置微調整
                    right: "-0.9em", // 微調整
                    zIndex: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 3,
                    whiteSpace: "nowrap",
                }}
            >
                {iClass &&
                    <i className={iClass}/>
                }
                {children &&
                <span>
                    {children}
                </span>
                }

            </div>
        )
    }
}