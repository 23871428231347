import React from "react";

interface Props {
    children?: React.ReactNode;
    left?: boolean
}
interface State {
    height: number
}
export class MapInfoWindow extends React.Component<Props, State> {
    private readonly ref:  React.RefObject<HTMLDivElement>
    constructor(props:Props) {
        super(props)
        this.ref = React.createRef();

        this.state = {
            height: 0
        }
    }

    componentDidMount() {
        if(!this.ref.current) return
        const rect = this.ref.current.getBoundingClientRect()
        this.setState({height: rect.height})
    }

    render() {

        const children = this.props.children
        const gutter = 40
        const width = 310
        const positionTop = this.state.height / -2
        const positionLeft = this.props.left ?  (width + gutter) * -1: gutter
        return (
            <div
                ref={this.ref}
                style={
                    {
                        position: "absolute",
                        width: 280,
                        zIndex: 1,
                        top: positionTop,
                        left: positionLeft,
                        borderRadius: 8,
                        boxShadow: "2px -2px 15px rgba(0,0,0,0.2)",
                        textAlign: "left",
                    }
                }>
                {children}
            </div>
        )
    }

}