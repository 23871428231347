import React from "react";
import {observer} from "mobx-react";
import carryStaffLocationsStore from '../stores/CarryStaffLocationsStore';
import requestStore from '../stores/RequestStore';
import GoogleMapReact from 'google-map-react';
import CarryStaffMarker from './CarryStaffMarker'
import SenderMarker from './SenderMarker'
import ReceiverMarker from './ReceiverMarker'
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";
import Request from "../interfaces/Request";
import format from 'date-fns/format'
import RequestPolyline from "../components/RequestPolyline";

declare var gon: any;

interface Props {
    request: Request
}

interface State {
    map: any,
    mapApi: any,
    mapLoaded: boolean,
    northEast: any,
    southEast: any,
    southWest: any,
    northWest: any
}


class RequestMap extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            map: null,
            mapApi: null,
            mapLoaded: false,
            northEast: null,
            southEast: null,
            southWest: null,
            northWest: null
        };
    }

    componentDidMount(): void {
        const {sender} = this.props.request;

        carryStaffLocationsStore.setCenter(sender);

        requestStore.subscribeRequest(this.props.request.id);
    }

    render() {
        const {request} = this.props;
        return (
            <div className={'request-map-container'}>
        <span
            id="request-map-postion-read-at"
            style={{position: 'absolute', zIndex: 100, top: 0, left: 4}}
        >
          {`位置情報 読込日時:  ${format(carryStaffLocationsStore.latestLoadedAt, 'HH:mm:ss')}`}
        </span>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: gon.google_api_key
                    }}
                    defaultCenter={request.sender}
                    defaultZoom={16}
                    center={request.sender}
                    resetBoundsOnResize={true}
                    hoverDistance={MapAttributes.K_SIZE / 2}
                    onGoogleApiLoaded={({map, maps}) => this.setState({
                        map: map,
                        mapApi: maps,
                        mapLoaded: true
                    })}
                    onChange={
                        (value) => {
                            this.setState(
                                {
                                    northEast: value.bounds.ne,
                                    southEast: value.bounds.se,
                                    southWest: value.bounds.sw,
                                    northWest: value.bounds.nw
                                }, () => {
                                    this.subscribe();
                                }
                            )
                        }
                    }
                >
                    <SenderMarker
                        lat={request.sender.lat}
                        lng={request.sender.lng}
                        request={request}
                    />
                    <ReceiverMarker
                        lat={request.receiver.lat}
                        lng={request.receiver.lng}
                        request={request}
                    />
                    {this.createCarryStaffMarkers()}
                    {this.renderCarryStaffPolyline()}
                </GoogleMapReact>
            </div>
        );
    }

    subscribe() {
        carryStaffLocationsStore.subscribeCarryStaff(this.state.northEast, this.state.southEast, this.state.southWest, this.state.northWest);
    }

    private createCarryStaffMarkers() {
        return _.map(carryStaffLocationsStore.locations.slice(), (location) => {
            return (
                <CarryStaffMarker
                    lat={location.lat}
                    lng={location.lng}
                    location={location}
                    request={this.props.request}
                    key={location.id}
                />
            )
        })
    }

    private renderCarryStaffPolyline() {
        if (!this.state.mapLoaded) {
            return
        }
        if (!requestStore.request) {
            return
        }

        return (
            <RequestPolyline
                map={this.state.map}
                mapApi={this.state.mapApi}
                request={requestStore.request}
            />
        )
    }
}

export default observer(RequestMap);
