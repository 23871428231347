import React from "react";
import Request from "../interfaces/Request";
import {MapMarkerPin} from "./MapMarkerPin";
import Colors from "../constants/BootstrapColors";
import {MapInfoWindow} from "./MapInfoWindow";
import {SenderCard} from "./MapInfoWindow/SenderCard";

interface Props {
    lat: number,
    lng: number,
    zoomLevel?: number,
    // GoogleMap pass $hover props to hovered components
    // to detect hover it uses internal mechanism, explained in x_distance_hover example
    $hover?: boolean,

    request: Request
}

interface State {
    showInfoWindow: boolean
}

export default class SenderMarker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showInfoWindow: false
        };
    }

    render() {
        const showInfoWindow = this.state.showInfoWindow;
        const pinSize = 31
        const badgeText = () => {
            if (this.props.request.isReserved) return "予約"
            return null
        }
        return (
            <div>
                <MapMarkerPin
                    icon={"shop"}
                    shape="circle"
                    size={showInfoWindow ? pinSize * 1.5 : pinSize}
                    pinColor={"#fff"}
                    iconColor={Colors.PRIMARY_COLOR}
                    onClick={this.onClickMarker.bind(this)}
                    badgeText={badgeText()}
                />
                {showInfoWindow && (
                    <MapInfoWindow>
                        <SenderCard
                            request={this.props.request}
                            onCloseClick={() => {
                                this.setState({
                                    showInfoWindow: false
                                })
                            }}
                        />
                    </MapInfoWindow>
                )}
            </div>
        )
    }

    private onClickMarker(event: Event) {
        this.setState({showInfoWindow: !this.state.showInfoWindow})
        event.preventDefault();
        event.stopPropagation();
    }
}
