import { action, observable, set } from "mobx";
import RequestsResponse from "../interfaces/RequestsResponse";
import { CarryStaffModel } from "../models/CarryStaffModel";
import Request from "../interfaces/Request";

export class RequestModel implements Request{
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  statusText: string;
  status: number;

  sender: {
    name: string
    address: string
    lat: number
    lng: number
  };

  receiver: {
    name: string
    address: string
    lat: number
    lng: number
  };

  carryStaff: CarryStaffModel;

  carryStaffId: number | null;

  deliveryTimeAt: Date | null;

  readyTimeAt: Date | null;

  isReserved: boolean | null;

  constructor(response: RequestsResponse) {
    this.id = response.id;
    this.key = response.id;
    this.sender = response.sender;
    this.receiver = response.receiver;
    this.status = response.status;
    this.statusText = response.status_text;

    this.deliveryTimeAt = null;
    if (response.delivery_time_at) {
      this.deliveryTimeAt = new Date(response.delivery_time_at);
    }

    this.readyTimeAt = null;
    if (response.ready_time_at) {
      this.readyTimeAt = new Date(response.ready_time_at);
    }

    if (response.carry_staff) {
      this.carryStaff = new CarryStaffModel(response.carry_staff);
      this.carryStaffId = response.carry_staff.id
    }

    this.isReserved = response.is_reserved
  }

  public isEarlyStage() {
    // 未確認、または、確認済み
    return this.status < 2
  }

  public isDeliveryStage() {
    // ピックアップ完了以降
    return 2 === this.status
  }

  public isDoneStage() {
    // ピックアップ完了以降
    return 2 < this.status
  }

}
