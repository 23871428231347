
import React from "react";
import {Alert, Card, TabContainer, Table} from "react-bootstrap";
import {CurrentLocationWithCarryStaffModel} from "../../models/CurrentLocationWithCarryStaffModel";

interface Props {
    location: CurrentLocationWithCarryStaffModel
    onCloseClick?: (event: any ) => void
}
export class CarryStaffCard extends React.Component<Props> {
    render() {
        const location = this.props.location
        const onCloseClick = this.props.onCloseClick
        const alertMayStay = this.renderAlertMayStay()
        const alertAvoidAssign = this.renderAlertAvoidAssign()
        return (
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <div style={{fontSize: "0.75rem"}}>
                            配達スタッフ</div>
                        <div onClick={onCloseClick}>閉じる</div>
                    </div>
                </Card.Header>
                <Card.Body  style={{fontSize: "0.85rem"}} className="mx-0 mb-0 pb-0">
                    <Card.Title style={{fontSize: "1rem"}}>{location.name}</Card.Title>
                        <Table>
                            <tbody>
                                <tr>
                                    <th>
                                        依頼
                                    </th>
                                    <td>
                                        {this.progressText() }
                                    </td>
                                </tr>
                                <tr>
                                    <th >
                                        移動手段
                                    </th>
                                    <td>
                                        {this.moveMethodText()}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    { alertMayStay }
                    { alertAvoidAssign }
                    </Card.Body>
                </Card>
        )
    }
    private progressText() {
        if (this.props.location.inProgressRequestCount === 0) {
            return "待機中"
        }
        return `${this.props.location.inProgressRequestCount}件 対応中`
    }
    private moveMethodText() {
        switch (this.props.location.moveMethod ) {
            case 0:
                return "自転車"
            case 10:
                return "バイク"
            case 20:
                return "車"
            default:
                return "未設定"

        }
    }
    private renderAlertMayStay() {
        if (this.props.location.mayStayForAWhile) {
            return (
                <Alert variant={"danger"} className="mx-0 pa-1">
                    <i className="fas fa-exclamation-triangle" style={{marginRight: 4}}/>
                    動いていない可能性があります
                </Alert>
            )
        }
        return null
    }

    private renderAlertAvoidAssign() {
        if (this.props.location.carryStaffAvoidAssign) {
            return (
                <Alert variant={"danger"} className="mx-0 pa-1">
                    <i className="fas fa-exclamation-triangle" style={{marginRight: 4}}/>
                    アサイン拒否中
                </Alert>
            )
        }
        return null
    }
}
