import axios from 'axios';
import { action, observable } from "mobx";

export class RequestAlertStore {
  @observable
  alertNum: number = 0;

  @observable
  soundAlertNum: number = 0;

  @observable
  hasSoundAlert: boolean = false;

  @action
  public async getAlertNum() {
    await axios.get(`/api/today_request_alert_num/`).then((response) =>{
      this.setAlertNum(Number(response.data.alert_num), Number(response.data.sound_alert_num));
    });
  }

  @action
  private setAlertNum(alertNum: number, soundAlertNum: number) {
    this.alertNum = alertNum;
    this.soundAlertNum = soundAlertNum;
    this.hasSoundAlert = (this.soundAlertNum > 0);
  }
}

const singleton = new RequestAlertStore();
export default singleton
