import React from "react";
import axios from 'axios';
import {observer} from "mobx-react";
import {csrfToken} from 'rails-ujs';
import {Slide, ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import Wkt from 'wicket';
import DeliveryArea from "../interfaces/DeliveryArea";
import {TownBorder} from "../interfaces/TownBorder";
import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";
import {Coords} from "google-map-react";

declare var gon: any;

const DISTANCE_SCALE = 3

interface Props {
    deaasTemplateId: number
    deliveryArea: DeliveryArea
}

interface State {
    deliveryAreaWkt: string | null
    townBorders: TownBorder[]
    townBordersRadius: number
    townBordersCenter: Coords
}

class DeliveryAreaMapEditorDeaasTemplate extends React.Component<Props, State> {

    wkt: any = null;

    constructor(props: Props) {
        super(props);
        this.state = {
            deliveryAreaWkt: props.deliveryArea.area_wkt,
            townBorders: [],
            townBordersRadius: 0,
            townBordersCenter: {lat: props.deliveryArea.lat, lng: props.deliveryArea.lng},
        };
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
        this.wkt = new Wkt.Wkt();
    }

    validateWktText = (wktText: string) => {
        try {
            this.wkt.read(wktText);
        } catch (error) {
            return false
        }
        return true
    }


    updateByWkt = (targetArea: string) => {
        const isValid = this.validateWktText(targetArea)
        return new Promise<void>(async (resolve, reject) => {
            try {

                if (!isValid) throw new Error("wktの形式が不正です")

                const res = await axios.post(
                    `/api/deaas_templates/${this.props.deaasTemplateId}/update_delivery_area_wkt`,
                    {delivery_area: targetArea})
                this.setState({
                    deliveryAreaWkt: res.data.delivery_area,
                })
                resolve()
            } catch (e) {
                console.error(e)
                reject(e)
            }
        })
    }

    destroy = async () => {
        let options = {
            autoClose: 1000,
            closeButton: false,
            type: toast.TYPE.INFO,
            hideProgressBar: true,
            position: toast.POSITION.TOP_CENTER,
            transition: Slide
        };
        try {
            const res = await axios.post(`/api/deaas_templates/${this.props.deaasTemplateId}/delete_delivery_area`);
            this.setState({deliveryAreaWkt: ""});
            toast.success("削除しました。", options);
        } catch (e) {
            toast.error("削除に失敗しました。", options);
        }
    }

    render() {
        const {deliveryArea} = this.props;
        const {deliveryAreaWkt} = this.state

        return (
            <div style={{width: "100%", height: "800px"}}>
                <MapAreaPicker
                    gmapsApiKey={gon.google_api_key}
                    center={deliveryArea}
                    wkt={deliveryAreaWkt}
                    onUpdateByWkt={this.updateByWkt}
                    onDestroy={this.destroy}
                />
                <ToastContainer/>
            </div>
        );
    }
}

export default observer(DeliveryAreaMapEditorDeaasTemplate);
