import React from "react";
import { observer } from "mobx-react";
import carryStaffLocationsStore from '../stores/CarryStaffLocationsStore';
import requestsStore from '../stores/RequestsStore';
import GoogleMapReact from 'google-map-react';
import OfficeCarryStaffMarker from './OfficeCarryStaffMarker'
import SenderMarker from './SenderMarker'
import ReceiverMarker from './ReceiverMarker'
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";
import Office from "../interfaces/Office";
import format from 'date-fns/format'
import OfficeMarker from "../components/OfficeMarker";
import RequestPolyline from "../components/RequestPolyline";

declare var gon: any;

interface Props {
  office: Office
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean,
  northEast: any,
  southEast: any,
  southWest: any,
  northWest: any
}


class OfficeMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      northEast: null,
      southEast: null,
      southWest: null,
      northWest: null
    };
  }

  componentDidMount(): void {
    carryStaffLocationsStore.setCenter(this.props.office);

    requestsStore.setCenter(this.props.office);
  }

  render() {
    const { office } = this.props;
    return (
      <div className={'request-map-container'}>
        <span
          style={{ position: 'absolute', zIndex: 100, top: 0, left: 4 }}
        >
          {`位置情報 読込日時:  ${format(carryStaffLocationsStore.latestLoadedAt, 'HH:mm:ss')}`}
        </span>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={office}
          defaultZoom={16}
          center={office}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
          onChange={
            (value) => {
              this.setState(
                {
                  northEast: value.bounds.ne,
                  southEast: value.bounds.se,
                  southWest: value.bounds.sw,
                  northWest: value.bounds.nw
                }, () => {
                  this.subscribe();
                }
              )
            }
          }
        >
          <OfficeMarker
            lat={office.lat}
            lng={office.lng}
          />
          {/*<ReceiverMarker*/}
          {/*  lat={request.receiver.lat}*/}
          {/*  lng={request.receiver.lng}*/}
          {/*  request={request}*/}
          {/*/>*/}
          {this.createCarryStaffMarkers()}
          {this.renderRequestSenderMarkers()}
          {this.renderRequestReceiverMarkers()}
          {this.renderRequestPolyline()}
        </GoogleMapReact>
      </div>
    );
  }

  subscribe() {
    carryStaffLocationsStore.subscribeCarryStaff(this.state.northEast,this.state.southEast,this.state.southWest,this.state.northWest);
    requestsStore.subscribeRequests(this.state.northEast,this.state.southEast,this.state.southWest,this.state.northWest);
  }

  private createCarryStaffMarkers() {
    return _.map(carryStaffLocationsStore.locations.slice(), (location) => {
      return (
        <OfficeCarryStaffMarker
          lat={location.lat}
          lng={location.lng}
          location={location}
        />
      )
    })
  }

  private renderRequestSenderMarkers() {
    return _.map(requestsStore.requests.slice(), (request) => {
      return (
       <SenderMarker
          lat={request.sender.lat}
          lng={request.sender.lng}
          request={request}
        />
      )
    })
  }

  private renderRequestReceiverMarkers() {
    return _.map(requestsStore.requests.slice(), (request) => {
      return (
       <ReceiverMarker
          lat={request.receiver.lat}
          lng={request.receiver.lng}
          request={request}
        />
      )
    })
  }

  private renderRequestPolyline() {
    if (!this.state.mapLoaded) {
      return
    }

    return _.map(requestsStore.requests.slice(), (request) => {
      return (
       <RequestPolyline
          map={this.state.map}
          mapApi={this.state.mapApi}
          request={request}
        />
      )
    })
  }
}

export default observer(OfficeMap);
