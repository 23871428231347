import { action, observable, set } from "mobx";
import _ from "lodash";
import { VisibleVendorCompanyModel } from "../models/VisibleVendorCompanyModel";
import { VisibleOfficeModel } from "../models/VisibleOfficeModel";

export interface NewMarker {
  lat: number,
  lng: number,
  visible: boolean,
  id: number,
  isDest: boolean,
}

declare var google: any;

export class SpotRemarkMapStore {
  visibleOffices = observable<VisibleOfficeModel>([]);
  visibleVendorCompanies = observable<VisibleVendorCompanyModel>([]);

  visibleNewMarkers = observable<NewMarker>([]);

  @observable
  vendorCompanyRange: number = 30;

  @observable
  serviceAreaRange: number = 1500;

  @observable
  newMarkerRange: number = 1500;

  newMarkerCount: number = 0;

  map: any;

  constructor() {
    set(this.visibleNewMarkers, []);
  }

  @action
  public setMap(map) {
    this.map = map;
  }

  @action
  private replaceNewMarkers(newMarkers) {
    this.visibleNewMarkers.replace(newMarkers)
  }

  @action
  public toggleNewMarker(newMarker) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return newMarker.id == object.id
    })
    findNewMarker.visible = !findNewMarker.visible;
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public viewAllNewMarkers() {
    _.each(this.visibleNewMarkers, (object) => {
      object.visible = true
    })
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public hiddenAllNewMarkers() {
    _.each(this.visibleNewMarkers, (object) => {
      object.visible = false
    })
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public setVendorCompanyRange(meter: number) {
    this.vendorCompanyRange = meter
  }

  @action
  public setServiceAreaRange(meter: number) {
    this.serviceAreaRange = meter
  }

  @action
  public setNewMarkerRange(meter: number) {
    this.newMarkerRange = meter
  }

  @action
  public setDestMarkerVisible(isVisible: boolean) {
    const destMarker = _.find(this.visibleNewMarkers.slice(), (newMarker) => {
      return newMarker.isDest;
    });
    destMarker.visible = isVisible;
    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public addNewMarkerWithLatLng(lat, lng, isDest: boolean) {
    this.newMarkerCount++;
    const newMarkers = this.visibleNewMarkers.slice()
    newMarkers.push({
      lat: lat,
      lng: lng,
      isDest: isDest,
      visible: true,
      id: this.newMarkerCount
    })
    this.replaceNewMarkers(newMarkers);
  }

  // action不要
  // visibleのon/offだけで必ず保持してるので不要
  // public hasCenterMarker(): boolean {
  //   const centerMarker = _.filter(this.visibleNewMarkers.slice(), (newMarker) => {
  //     return newMarker.isDest
  //   })
  //   if (centerMarker.length > 0) {
  //     return true;
  //   }
  //   return false;
  // }

  @action
  public setInitialNewMarkers(markers: {lat: number, lng: number, isDest: boolean}[]) {
    const newMarkers = this.visibleNewMarkers.slice()
    for (const marker of markers) {
      this.newMarkerCount++;
      newMarkers.push({
        lat: marker.lat,
        lng: marker.lng,
        isDest: marker.isDest,
        visible: true,
        id: this.newMarkerCount
      })
    }
    this.replaceNewMarkers(newMarkers);
  }

  @action
  public changeDestMarkerPosition(lat: number, lng: number) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return object.isDest
    })
    findNewMarker.lat = lat;
    findNewMarker.lng = lng;

    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public changeNewMarkerPosition(newMarker: NewMarker, lat: number, lng: number) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return newMarker.id == object.id
    })
    findNewMarker.lat = lat;
    findNewMarker.lng = lng;

    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public changeMarkerPositionById(markerId: number, lat: number, lng: number) {
    const findNewMarker = _.find(this.visibleNewMarkers, (object) => {
      return markerId == object.id
    })
    findNewMarker.lat = lat;
    findNewMarker.lng = lng;

    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  @action
  public deleteMarker(markerId: number) {
    _.remove(this.visibleNewMarkers, function(object) {
      return markerId == object.id
    });

    this.replaceNewMarkers(this.visibleNewMarkers);
  }

  // 中心マーカを更新することになったのでactionが必要
  @action
  public moveCenter(address: string) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({
        address: address,
        region: 'jp'
      },
      (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          this.map.setCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng()
          });
          const destMarker = _.find(this.visibleNewMarkers.slice(), (newMarker) => {
            return newMarker.isDest
          });
          // 必ず存在するはず moveCenterの場合はdestMarkerの移動は行わない
          // this.changeNewMarkerPosition(
          //   destMarker, results[0].geometry.location.lat(), results[0].geometry.location.lng())
        }
      })
  }
}

const singleton = new SpotRemarkMapStore();
export default singleton
