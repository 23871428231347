import React from "react";

import { CurrentLocationWithCarryStaffModel } from "../models/CurrentLocationWithCarryStaffModel";
import MarkerColors from "../constants/MarkerColors"
import Colors from "../constants/BootstrapColors"
import {MapMarkerPin} from "./MapMarkerPin";
import {CarryStaffCard} from "./MapInfoWindow/CarryStaffCard";
import { MapInfoWindow} from "./MapInfoWindow"
interface Props {
  lat: number,
  lng: number,
  zoomLevel?: number,

  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,

  // keyを渡すとhoverがうまく判定できないので、key propsは任意にして値をセットしない
  key?: number,

  location: CurrentLocationWithCarryStaffModel,
}

interface State {
  showInfoWindow: boolean
}

export default class OfficeCarryStaffMarker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    const showInfoWindow = this.state.showInfoWindow || this.props.$hover

    // 依頼対応件数が1以上
    const isProgressRequest = this.props.location.inProgressRequestCount > 0
    // 動いていない可能性あり
    const mayStay = this.props.location.mayStayForAWhile;

    const carryStaffAvoidAssign = this.props.location.carryStaffAvoidAssign;

    const pinSize = 28
    const moveMethod = this.moveMethodToIcon()
    const pinColor = this.getPinColor({isProgressRequest, mayStay})
    return (
    <div>
      <MapMarkerPin
          icon={moveMethod}
          shape="drop"
          size={showInfoWindow ? pinSize * 1.5: pinSize}
          pinColor={pinColor}
          iconColor={!isProgressRequest ? "#ffffff": "#333"}
          badgeStyle={{
            iClass: this.getIclass({mayStay, carryStaffAvoidAssign}),
            color: Colors.DANGER_COLOR
            }}
          onClick={this.onClickMarker.bind(this)}
      />
        {showInfoWindow && (
            <MapInfoWindow>
              <CarryStaffCard
                  location={this.props.location}
                  onCloseClick={() => {
                    this.setState({
                      showInfoWindow: false
                    })}}
              />
            </MapInfoWindow>
      )}
    </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({ showInfoWindow: !this.state.showInfoWindow })
    event.preventDefault();
    event.stopPropagation();
  }

  private progressText() {
    if (this.props.location.inProgressRequestCount == 0) {
      return "待機中"
    }
    return `依頼${this.props.location.inProgressRequestCount}件 対応中 　`
  }

  private moveMethodToIcon() {
    switch (this.props.location.moveMethod) {
      case 0:
        return "bicycle"
      case 10:
        return "bike"
      case 20:
        return "car"
      case 30:
      default:
        return "walk"
    }
  }

  private getPinColor({isProgressRequest,mayStay} ) {
    if(isProgressRequest) return MarkerColors.PROGRESS_COLOR
    if(mayStay) return "#666"
    return MarkerColors.DEFAULT_COLOR
  }

  private getIclass({mayStay, carryStaffAvoidAssign}) {
    if (carryStaffAvoidAssign) return "far fa-hand-paper"
    if (mayStay) return "fas fa-sm fa-exclamation"
    return null;
  }
}
