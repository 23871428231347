import React from "react";
import axios, {AxiosResponse} from 'axios';
import {observer} from "mobx-react";
import {csrfToken} from 'rails-ujs';
import {Slide, ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import Wkt from 'wicket';
import DeliveryArea from "../interfaces/DeliveryArea";
import {TownBorder} from "../interfaces/TownBorder";
import MapAreaPicker from "./MapAreaPicker/MapAreaPicker";


declare var gon: any;

const DISTANCE_SCALE = 3

interface Props {
  deliveryArea: DeliveryArea,
  updateDeliveryArea: (deliveryAreaWkt: string) => Promise<AxiosResponse<any>>
  deleteDeliveryArea: () => Promise<AxiosResponse<any>>
}

interface State {
  map: any
  mapApi: any
  mapLoaded: boolean
  deliveryAreaWkt: string | null
  isInvalid: boolean
  townBorders: TownBorder[]
  distance: number,
  isLoadingTownBorders: boolean
}

class DeliveryAreaMapWithTownBorder extends React.Component<Props, State> {

  wkt: any = null;

  alertMessage: { type: "error" | "alert" | "notice" | "success"; text: string; } = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,
      deliveryAreaWkt: props.deliveryArea.area_wkt,
      isInvalid: false,
      townBorders: [],
      distance: 0,
      isLoadingTownBorders: false,
    };
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
    this.wkt = new Wkt.Wkt();
  }


  validateWktText = (wktText: string) => {
    try {
      this.wkt.read(wktText);
    } catch (error) {
      return false
    }
    return true
  }

  onChange = (event: any) => {
    const isValid = this.validateWktText(event.target.value)
    this.setState({
      deliveryAreaWkt: event.target.value,
      isInvalid: !isValid
    });
  }


  updateByWkt = (targetArea: string) => {
    const isValid = this.validateWktText(targetArea)
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (!isValid) throw new Error("wktの形式が不正です")
        const res = await this.props.updateDeliveryArea(this.state.deliveryAreaWkt);
        this.setState({
          deliveryAreaWkt: res.data.delivery_area,
        })
        resolve()
      } catch (e) {
        console.error(e)
        reject(e)
      }
    })
  }

  destroy = async () => {
    let options = {
      autoClose: 1000,
      closeButton: false,
      type: toast.TYPE.INFO,
      hideProgressBar: true,
      position: toast.POSITION.TOP_CENTER,
      transition: Slide
    };
    try {
      const res = await this.props.deleteDeliveryArea();
      this.setState({deliveryAreaWkt: ""});
      toast.success("削除しました。", options);
    } catch (e) {
      toast.error("削除に失敗しました。", options);
      throw e
    }
  }

  render() {
    const {deliveryArea} = this.props;
    const {deliveryAreaWkt} = this.state

    return (
      <div>
        <MapAreaPicker
            gmapsApiKey={gon.google_api_key}
            center={deliveryArea}
            wkt={deliveryAreaWkt}
            onUpdateByWkt={this.updateByWkt}
            onDestroy={this.destroy}
        />
        <ToastContainer/>
      </div>
    );
  }
}

export default observer(DeliveryAreaMapWithTownBorder);
