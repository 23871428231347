import React from "react";
import { observer } from "mobx-react";
import CarryStaffOverlookStore from '../stores/CarryStaffOverlookStore';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";
import OfficeSmallMarker from "../components/OfficeSmallMarker";
import CircleArea from "../components/CircleArea";
import carryStaffLocationsStore from "../stores/CarryStaffLocationsStore";
import requestsStore from "../stores/RequestsStore";
import OfficeCarryStaffMarker from "./OfficeCarryStaffMarker";
import SenderMarker from "./SenderSmallMarker";
import ReceiverMarker from "./ReceiverSmallMarker";
import RequestPolyline from "./RequestPolyline";
import RequestConnectPolyline from "./RequestConnectPolyline";
import MarkerColors from "../constants/MarkerColors";
import { RequestModel } from "../models/RequestModel";
import * as geolib from 'geolib';
import ServiceAreaPolygon from "./ServiceAreaPolygon";
import DeliveredTimeBeforeMinutesSelect from "./MapTools/DeliveredTimeBeforeMinutesSelect";

declare var gon: any;

interface Props {
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean,

  centerLat: number;
  centerLng: number;
  zoom: number;

  northEast: any,
  southEast: any,
  southWest: any,
  northWest: any;

}

interface RequestProp {
  isFirstRequest: boolean;
  request: RequestModel;
  distance: number;
}

interface RequestConnect {
  fromLat: number;
  fromLng: number;
  toLat: number;
  toLng: number;
}

class CarryStaffOverlookMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false,

      centerLat: this.getDefaultCenterLat(),
      centerLng: this.getDefaultCenterLng(),
      zoom: this.getDefaultZoom(),

      northEast: null,
      southEast: null,
      southWest: null,
      northWest: null,
    };
    this.getDeliverdTimeBeforeMinutes()

  }

  componentDidMount(): void {

    navigator.geolocation.getCurrentPosition((result: any) => {
      // this.setState({
      //   centerLat: result.coords.latitude,
      //   centerLng: result.coords.longitude,
      // })
    }, () => {
    });
    //this.subscribe();
    setInterval(() => CarryStaffOverlookStore.loadNotExistStaffServiceAreas(), 60_000 * 5);
  }

  subscribe() {
    carryStaffLocationsStore.setCenter({
      lat: this.state.centerLat, lng: this.state.centerLng
    });
    carryStaffLocationsStore.subscribeCarryStaff(this.state.northEast, this.state.southEast, this.state.southWest, this.state.northWest);

    requestsStore.setCenter({
      lat: this.state.centerLat, lng: this.state.centerLng
    });
    requestsStore.subscribeRequests(this.state.northEast, this.state.southEast, this.state.southWest, this.state.northWest);

    CarryStaffOverlookStore.loadInterSectServiceAreas(this.state.northEast, this.state.southEast, this.state.southWest, this.state.northWest);
  }

  render() {
    return (
      <div className={'request-map-container'}>
        <div className="time-span-controller">
          <DeliveredTimeBeforeMinutesSelect
              defaultValue={requestsStore.deliveredTimeBeforeMinutes}
              disabled={requestsStore.loading}
              onChange={(e) => {
                this.setDeliverdTimeBeforeMinutes(e.target.value)
                requestsStore.setDeliveredTimeBeforeMinutes( Number(e.target.value))
                requestsStore.subscribeRequests(this.state.northEast, this.state.southEast, this.state.southWest, this.state.northWest);
              }}
          />
        </div>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={{
            lat: this.state.centerLat,
            lng: this.state.centerLng
          }}
          defaultZoom={this.state.zoom}
          center={{
            lat: this.state.centerLat,
            lng: this.state.centerLng
          }}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={
            ({ map, maps }) => {
              CarryStaffOverlookStore.setMap(map);

              this.setState({
                map: map,
                mapApi: maps,
                mapLoaded: true
              })
            }
          }
          onChange={
            (value) => {
              this.setState(
                {
                  centerLat: value.center.lat,
                  centerLng: value.center.lng,
                  zoom: value.zoom,
                  northEast: value.bounds.ne,
                  southEast: value.bounds.se,
                  southWest: value.bounds.sw,
                  northWest: value.bounds.nw
                }, () => {
                  this.subscribe();
                }
              )
              this.setCurrentLatLngZoomToLocalStrage(value.center.lat, value.center.lng, value.zoom);
            }
          }
        >
          {this.createOfficeMarkers()}

          {this.createServiceAreaPolygon()}

          {this.createCarryStaffMarkers()}
          {this.renderRequestSenderMarkers()}
          {this.renderRequestReceiverMarkers()}
          {this.renderRequestPolyline()}
          {this.renderRequestConnectPolyline()}
        </GoogleMapReact>
      </div>
    );
  }

  private setCurrentLatLngZoomToLocalStrage(lat, lng, zoom) {
    localStorage.setItem(this.getDefaultCenterLatKey(), lat);
    localStorage.setItem(this.getDefaultCenterLngKey(), lng);
    localStorage.setItem(this.getDefaultZoomKey(), zoom);
  }
  private setDeliverdTimeBeforeMinutes(minute) {
    localStorage.setItem(this.getDeliverdTimeBeforeMinutesKey(), minute);
  }

  private getDefaultCenterLatKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_CENTER_LAT + gon.current_user_id;
  }

  private getDefaultCenterLngKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_CENTER_LNG + gon.current_user_id;
  }

  private getDefaultZoomKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_CARRYSTAFFOVERLOOKMAP_ZOOM + gon.current_user_id;
  }
  private getDeliverdTimeBeforeMinutesKey() {
    return MapAttributes.LOCAL_STRAGE_KEY_DELIVERD_TIME_BEFORE_MINUTES + gon.current_user_id;
  }
  private getDefaultCenterLat(): number {
    let lat = localStorage.getItem(this.getDefaultCenterLatKey());
    if (lat == null) {
      lat = gon.default_lat;
    }
    return Number(lat);
  }

  private getDefaultCenterLng(): number {
    let lng = localStorage.getItem(this.getDefaultCenterLngKey());
    if (lng == null) {
      lng = gon.default_lng;
    }
    return Number(lng);
  }

  private getDefaultZoom(): number {
    let zoom: number = Number(localStorage.getItem(this.getDefaultZoomKey()));
    if (zoom <= 1) {
      zoom = MapAttributes.DEFAULT_ZOOM;
    }
    return zoom;
  }
  private getDeliverdTimeBeforeMinutes() {
    const minutes = Number(localStorage.getItem(this.getDeliverdTimeBeforeMinutesKey())) || 0
    requestsStore.setDeliveredTimeBeforeMinutes(minutes)
  }

  private createOfficeMarkers() {
    return _.map(CarryStaffOverlookStore.visibleOffices.slice(), (visibleOffice) => {
      if (!visibleOffice.visible) {
        return
      }
      const model = visibleOffice.model;

      return (
        <OfficeSmallMarker
          key={Math.random()}
          lat={model.lat}
          lng={model.lng}
        />
      )
    })
  }

  private createServiceAreaPolygon() {
    if (_.isEmpty(this.state.map)) {
      return null
    }
    return _.map(CarryStaffOverlookStore.visibleServiceAreas.slice(), (visibleServiceArea) => {
      if (!visibleServiceArea.visible) {
        return
      }
      const { model } = visibleServiceArea;
      // バックエンドでの判定で不在、となったときのみServiceAreaPolygon内で判定を行わせる
      const notExistFlg = CarryStaffOverlookStore.notExistStaffServiceAreas.find(x => x.id === model.id);
      return (
        <ServiceAreaPolygon
          key={model.id}
          map={this.state.map}
          mapApi={this.state.mapApi}
          wktText={model.withinAreaWkt}
          staffLocations={notExistFlg ? carryStaffLocationsStore.locations : undefined}
        />
      )
    })
  }


  private createCarryStaffMarkers() {
    return _.map(carryStaffLocationsStore.locations.slice(), (location) => {
      return (
        <OfficeCarryStaffMarker
          key={location.id}
          lat={location.lat}
          lng={location.lng}
          location={location}
        />
      )
    })
  }

  private renderRequestSenderMarkers() {
    if (!CarryStaffOverlookStore.requestVisible) {
      return
    }

    return _.map(requestsStore.requests.slice(), (request) => {
      return (
        <SenderMarker
          lat={request.sender.lat}
          lng={request.sender.lng}
          request={request}
          key={`sender-marker-${request.id}`}
        />
      )
    })
  }

  private renderRequestReceiverMarkers() {
    if (!CarryStaffOverlookStore.requestVisible) {
      return
    }

    return _.map(requestsStore.requests.slice(), (request) => {
      return (
        <ReceiverMarker
          lat={request.receiver.lat}
          lng={request.receiver.lng}
          request={request}
          key={`receiver-marker-${request.id}`}
        />
      )
    })
  }

  private renderRequestPolyline() {
    if (!this.state.mapLoaded) {
      return
    }

    if (!CarryStaffOverlookStore.requestVisible) {
      return
    }

    const requestProps: RequestProp[] = this.getRequestProps(requestsStore.requests);

    return _.map(requestProps.slice(), (requestProp) => {
      return (
        <RequestPolyline
          map={this.state.map}
          mapApi={this.state.mapApi}
          isFirstRequest={requestProp.isFirstRequest}
          request={requestProp.request}
          key={`request-polyline-${requestProp.request.id}`}
        />
      )
    })
  }

  private getRequestProps(requests) {
    let result: RequestProp[] = [];
    let requestsByStaff: RequestModel[] = [];
    let lastCarryStaffId: number | null = null;
    let isFirstItem = true;
    const sortedRequest = _.sortBy(requests, ["carryStaffId"]);
    _.forEach(sortedRequest, (request) => {
      if (!isFirstItem && lastCarryStaffId != request.carryStaffId) {
        result = result.concat(this.genRequestProps(requestsByStaff));
        requestsByStaff = [];
      }
      requestsByStaff.push(request);
      lastCarryStaffId = request.carryStaffId;
      isFirstItem = false;
    });
    if (requestsByStaff.length > 0) {
      result = result.concat(this.genRequestProps(requestsByStaff));
    }
    return result;
  }

  private genRequestProps(requests: RequestModel[]) {
    let result: RequestProp[] = [];
    let aryForSort: RequestProp[] = [];

    _.forEach(requests, (request) => {
      if (!request.carryStaff || !request.carryStaff.lat || request.isDoneStage()) {
        result.push(this.makeRequestProps(request, false, -1));
      } else {
        const staffLat = request.carryStaff.lat;
        const staffLng = request.carryStaff.lng;
        let targetLat = 0;
        let targetLng = 0;
        if (request.isEarlyStage()) {
          targetLat = request.sender.lat;
          targetLng = request.sender.lng;
        } else if (request.isDeliveryStage()) {
          targetLat = request.receiver.lat;
          targetLng = request.receiver.lng;
        }
        const dist = geolib.getDistance(
          { lat: staffLat, lng: staffLng },
          { lat: targetLat, lng: targetLng }
        );
        aryForSort.push(this.makeRequestProps(request, false, dist));
      }
    });

    if (aryForSort != null && aryForSort.length > 0) {
      aryForSort = _.sortBy(aryForSort, ["distance"]);
      aryForSort[0].isFirstRequest = true;
      result = result.concat(aryForSort);
    }

    return result;
  }

  private makeRequestProps(request: RequestModel, isFirstRequest: boolean, distance: number) {
    const result: RequestProp = {
      request: request,
      isFirstRequest: isFirstRequest,
      distance: distance
    };
    return result;
  }

  private renderRequestConnectPolyline() {
    if (!this.state.mapLoaded) {
      return
    }

    if (!CarryStaffOverlookStore.requestVisible) {
      return
    }

    const connects: RequestConnect[] = this.getRequestConnects(requestsStore.requests);

    return _.map(connects.slice(), (connect) => {
      return (
        <RequestConnectPolyline
          map={this.state.map}
          mapApi={this.state.mapApi}
          fromLat={connect.fromLat}
          fromLng={connect.fromLng}
          toLat={connect.toLat}
          toLng={connect.toLng}
          key={Math.random()}
        />
      )
    })
  }

  private getRequestConnects(requests) {
    const sortedRequest = _.sortBy(requests, ["carryStaffId", "readyTimeAt"]);
    const result: RequestConnect[] = [];
    let lastCarryStaffId: number | null = null;
    let lastRecieverLat: number = -1;
    let lastRecieverLng: number = -1;
    _.forEach(sortedRequest, (request) => {
      //スタッフ未アサインは除外する
      if (request.carryStaffId != null && lastCarryStaffId == request.carryStaffId) {
        const connect: RequestConnect = {
          fromLat: lastRecieverLat,
          fromLng: lastRecieverLng,
          toLat: request.sender.lat,
          toLng: request.sender.lng
        };
        result.push(connect);
      }
      lastCarryStaffId = request.carryStaffId;
      lastRecieverLat = request.receiver.lat;
      lastRecieverLng = request.receiver.lng;
    });
    return result;
  }
}

export default observer(CarryStaffOverlookMap);
