import React from "react";
import { observer } from "mobx-react";
import deaasAreaOverlookStore from '../stores/DeaasAreaOverlookStore';
import _ from 'lodash'

interface Props {
}

interface State {
  searchCondition: string | null;
}

class DeaasAreaOverlookServiceAreaList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchCondition: ""
    }
  }

  componentDidMount(): void {
  }

  render() {
    if (_.isEmpty(deaasAreaOverlookStore.visibleServiceAreas)) {
      return (
        <div>
          {this.renderSearchBox()}
          有効なサービスエリアがありませんでした。
        </div>
      )
    }

    return (
      <div>
        {this.renderSearchBox()}
        {this.renderServiceAreaList()}
      </div>
    );
  }

  private renderServiceAreaList() {
    return _.map(deaasAreaOverlookStore.visibleServiceAreas, (serviceArea) => {

      if (!this.matchSearchCondition(serviceArea.model.name)) {
        return null
      }
      return (
        <div className={"form-check mb-2"} key={serviceArea.model.id}>
          <input type="checkbox" className={"form-check-input"} id={`servicearea-${serviceArea.id}`}
                 checked={serviceArea.visible} onClick={() => {
            deaasAreaOverlookStore.toggleServiceAreaVisible(serviceArea);
          }} />
          <label className={"form-check-label"} htmlFor={`servicearea-${serviceArea.id}`}>
            {serviceArea.model.name}
          </label>
          <a href={`/service_areas/${serviceArea.model.id}`}>
            詳細
          </a>
        </div>
      )
    })
  }

  private renderSearchBox() {
    return (
      <React.Fragment>
        <div className="form-group form-inline mb-2">
          <input type="number" className="form-control input-overlook-range"
                 value={deaasAreaOverlookStore.serviceAreaRange}
                 placeholder={""}
                 onChange={(event) => {
                   deaasAreaOverlookStore.setServiceAreaRange(Number(event.target.value))
                 }}
          />
          <span>㍍</span>
          <button className="btn btn-primary ml-2" onClick={() => {
            deaasAreaOverlookStore.viewAllServiceAreas()
          }}>
            <i className={"fas fa-eye"} />
          </button>
          <button className="btn btn-primary ml-2" onClick={() => {
            deaasAreaOverlookStore.hiddenAllServiceAreas()
          }}>
            <i className={"fas fa-eye-slash"} />
          </button>
        </div>
        <div className="form-group mb-2">
          <input type="text" className="form-control" value={this.state.searchCondition}
                 placeholder={"スペースで複数指定"}
                 onChange={(event) => {
                   this.setState({
                     searchCondition: event.target.value
                   })
                 }}
          />
        </div>
      </React.Fragment>
    )
  }

  private matchSearchCondition(name: string) {
    const searchConditions = this.state.searchCondition.trim().split(/[　 ]/);
    if (searchConditions.length == 0) {
      return true
    }

    return _.some(searchConditions, (searchCondition) => {
      return (name.indexOf(searchCondition) > -1)
    })
  }
}

export default observer(DeaasAreaOverlookServiceAreaList);
