import { action, observable, set } from "mobx";
import ServiceAreasResponse from "../interfaces/ServiceAreasResponse";
import SpotRemarksResponse from "../interfaces/SpotRemarksResponse";

export class SpotRemarkModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;


  name: string;
  keyword: string;
  memo: string;
  useKeyword: number;
  centerLat: number;
  centerLng: number;
  withinAreaWkt: string;

  useDestPoint: number;
  destLat: number;
  destLng: number;

  constructor(response: SpotRemarksResponse) {
    this.id = response.id;
    this.key = response.id;

    this.name = response.name;
    this.keyword = response.keyword;
    this.memo = response.memo;
    this.useKeyword = Number(response.use_keyword);
    this.centerLat = Number(response.center_lat);
    this.centerLng = Number(response.center_lng);
    this.withinAreaWkt = response.within_area_wkt;

    this.useDestPoint = response.use_dest_point;
    this.destLat = (isNaN(response.dest_lat))? null : Number(response.dest_lat);
    this.destLng = (isNaN(response.dest_lng))? null : Number(response.dest_lng);
  }

}
