import {useEffect, useState} from "react";
// @ts-ignore
import {stringify, parse} from "wkt"
import type {PickMode} from "../MapAreaPicker";

interface Props {
    map?: google.maps.Map
    pickMode: PickMode
    wkt: string
    selectedFeature?: google.maps.Data.Feature
}

/**
 * GoogleMapsDataLayer関連のフックス
 */
export default function usePathMode(props: Props) {
    const {map, pickMode, wkt, selectedFeature} = props
    // Polygon = パスを追加 null = パスを調整
    const [editMode, setEditMode] = useState<"Polygon" | null>("Polygon")


    useEffect(() => {
        // パスを選択するモードとエリアを調整モードでは
        // データレイヤーの編集を可能にする
        if (!map) return
        map.data.setStyle({
            editable: pickMode == "Paths",
            visible: true
        });

        // パスを追加するモードの場合はDrawingModeをポリゴン描画モード(Polygon)にする
        // パスを調整モードでは選択モード(null)にする
        if (pickMode == "Paths") {
            map.data.setDrawingMode("Polygon")
            setEditMode("Polygon")
        } else {
            map.data.setDrawingMode(null)
            setEditMode(null)
        }

        // それ以外の場合は、選択中エリアをデータレイヤーに表示
        map.data.forEach(feature => map.data.remove(feature))

        if (selectedFeature) map.data.addGeoJson(selectedFeature)

    }, [map, pickMode, wkt])


    return {
        editMode,
        setEditMode,
    }
}

