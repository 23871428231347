import React from "react";
import axios from 'axios';
import { observer } from "mobx-react";

import DeliveryArea from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithHexSelection from "./DeliveryAreaMapWithHexSelection";


interface Props {
  vendorCompanyId: number
  deliveryArea: DeliveryArea
}

class DeliveryAreaMapWithHexSelectionVendorCompany extends React.Component<Props> {
  render() {
    return (
      <DeliveryAreaMapWithHexSelection
        deliveryArea={this.props.deliveryArea}
        updateDeliveryArea={async (targetArea) => {
          return await axios.post(
            `/api/vendor_companies/${this.props.vendorCompanyId}/update_delivery_area`,
            {
              area: targetArea
            });
        }}
      />
    );
  }
}

export default observer(DeliveryAreaMapWithHexSelectionVendorCompany);
