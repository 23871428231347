import {action, observable} from "mobx";
import {CurrentLocationWithCarryStaffModel} from "../models/CurrentLocationWithCarryStaffModel";
import CurrentLocationsResponse from "../interfaces/CurrentLocationsResponse";
import _ from "lodash";

export class CarryStaffLocationsStore {
  locations = observable<CurrentLocationWithCarryStaffModel>([]);

  @observable
  centerLat: number = 0;

  @observable
  centerLng: number = 0;

  @observable
  latestLoadedAt: Date = new Date();

  intervalTimer: any | null = null;

  // 非表示ドライバーリスト
  hiddenLocationIds: number[] = []

  @action
  public setCenter({lat, lng}) {
    this.centerLat = lat;
    this.centerLng = lng;
  }

  @action
  public subscribeCarryStaff(ne, se, sw, nw) {
    this.loadCarryStaff(ne, se, sw, nw);
    if (this.intervalTimer) {
      clearInterval(this.intervalTimer)
    }
    this.intervalTimer = setInterval(this.loadCarryStaff.bind(this, ne, se, sw, nw), 30000)
  }

  @action
  private loadCarryStaff(ne, se, sw, nw) {
    // @ts-ignore
    const params = new URLSearchParams([['lat', this.centerLat], ['lng', this.centerLng], ['neLng', ne.lng], ['neLat', ne.lat], ['seLng', se.lng], ['seLat', se.lat], ['swLng', sw.lng], ['swLat', sw.lat], ['nwLng', nw.lng], ['nwLat', nw.lat]]);
    fetch('/api/current_locations?' + params.toString())
      .then(response => response.json())
      .then((results: Array<CurrentLocationsResponse>) => {
        const locations = [] as Array<CurrentLocationWithCarryStaffModel>;
        _.forEach(results, (result: CurrentLocationsResponse) => {
          const model = new CurrentLocationWithCarryStaffModel(result)

          // IDが非表示ドライバーリストに含まれている場合は非表示にする
          // @ts-ignore
          if( this.hiddenLocationIds.includes(model.id)) model.visible = false

          locations.push(model)
        });
        this.replaceLocation(locations);
      })

    this.latestLoadedAt = new Date()
  }

  @action
  private replaceLocation(locations) {
    this.locations.replace(locations)
  }

  @action
  public toggleCarryStaffVisible(carryStaff:CurrentLocationWithCarryStaffModel ) {
    const location = _.find(this.locations, (object) => {
      return object.id == carryStaff.id
    })
    location.visible = !location.visible;

    if (location.visible) {
      // 非表示から表示になった場合、IDを非表示ドライバーリストから除外
      this.hiddenLocationIds = this.hiddenLocationIds.filter((id) => {
        return location.id !== id
      })
    } else {
      //表示から非表示になった場合、IDを非表示ドライバーリストに追加
      this.hiddenLocationIds.push(location.id)
    }
    this.locations.replace(this.locations)
  }
}

const singleton = new CarryStaffLocationsStore();
export default singleton
