import React from "react";
import {Alert, Button, Card, Table} from "react-bootstrap";
import Request from "../../interfaces/Request";
import {format} from "date-fns";

interface Props {
    request: Request
    onCloseClick?: (event: any) => void
}

export class ReceiverCard extends React.Component<Props> {
    render() {
        const request = this.props.request
        const onCloseClick = this.props.onCloseClick
        const alert = this.renderAlert()
        return (
            <Card>
                <Card.Header>
                    <div className="d-flex justify-content-between">
                        <div style={{fontSize: "0.75rem"}}>
                            受け取り先
                        </div>
                        <div onClick={onCloseClick}>閉じる</div>
                    </div>
                </Card.Header>
                <Card.Body style={{fontSize: "0.85rem"}} className="mx-0 mb-0 pb-0">
                    <Card.Title style={{fontSize: "1rem"}}>
                        {request.receiver.name}
                    </Card.Title>
                    <Table>
                        <tbody>
                        <tr>
                            <th style={{whiteSpace: "nowrap"}}>
                                {request.carryStaff ? request.carryStaff.name : "未確認" }
                            </th>
                            <td>
                                {request.statusText}
                            </td>
                        </tr>
                        <tr>
                            <th style={{whiteSpace: "nowrap"}}>
                                住所
                            </th>
                            <td>
                                {request.receiver.address}
                            </td>
                        </tr>
                        {request.deliveryTimeAt &&
                            <tr>
                                <th style={{whiteSpace: "nowrap"}}>
                                    配達希望
                                </th>
                                <td>
                                    {format(request.deliveryTimeAt, 'MM月dd日 HH:mm')}
                                </td>
                            </tr>
                        }
                        </tbody>
                    </Table>
                    {alert}
                </Card.Body>
                <Card.Footer>
                    <Button size="sm" onClick={this.handlerClickMoveDetail.bind(this)}>
                        依頼No.{request.id}詳細へ
                    </Button>
                </Card.Footer>

            </Card>
        )
    }

    private handlerClickMoveDetail(event: any) {
        const requestEditUrl = `/requests/${this.props.request.id}/edit`;
        open(requestEditUrl, "_blank");
        event.preventDefault();
        event.stopPropagation();
    }

    private renderAlert() {
        if (this.props.request.isReserved) {
            return (
                <Alert variant={"warning"} className="mx-0 pa-1">
                    <i className="fas fa-clock" style={{marginRight: 4}}/>
                    この依頼は予約です
                </Alert>
            )
        }
        return null
    }
}