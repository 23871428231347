import React from "react";
// @ts-ignore
import Wkt from 'wicket';
import _ from "lodash";


interface DefaultProps {
  strokeColor: string
  strokeOpacity: number
  strokeWeight: number
  fillColor: string
  fillOpacity: number
}

interface Props extends Partial<DefaultProps> {
  mapApi: any
  map: any
  wktText: string
  areaKey?: string
  onClickPolygon?: () => void
  onRightClickPolygon?: () => void
  onMouseover?: (e: google.maps.PolyMouseEvent) => void
  onMouseout?: (e: google.maps.PolyMouseEvent) => void
  onMousemove?: (e: google.maps.PolyMouseEvent) => void
}

interface PolygonGroup {
  // LatLng[]が一つしか無いときは穴なし。 pathが配列のときは穴あきで１つ目が外側。２つ目が内側
  // @ts-ignore
  path: google.maps.LatLng[] | [google.maps.LatLng[], google.maps.LatLng[]]
}

class WktMultiPolygon extends React.PureComponent<Props> {

  wkt: any = null;
  polygonGroups: PolygonGroup[] = [];
  polygons: any[] = []
  timer: any = null

  public static defaultProps: Partial<DefaultProps> = {
    strokeColor: "#79baff",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#79baff",
    fillOpacity: 0.20
  };

  constructor(props: Props) {
    super(props);
    this.wkt = new Wkt.Wkt();
    this.updateView()
  }

  componentDidMount() {
  }

  componentDidUpdate() {
    // propsが更新されば場合は既存のオブジェクトをmap上から削除
    _.each(this.polygons, (polygon) => {
      polygon.setMap(null);
    })
    this.updateView()
  }

  componentWillUnmount() {
    _.each(this.polygons, (polygon) => {
      polygon.setMap(null);
    })
  }

  render() {
    return null
  }

  private updateView() {
    try {
      this.wkt.read(this.props.wktText);
      if (this.wkt.type === "polygon") {
        this.makePathsForPolygon();
      } else {
        // MultiPolygonの場合は、type === "multipolygon"
        this.makePathsForMulitiPolygon();
      }
    } catch (error) {
      console.log(error)
    }

    _.each(this.polygonGroups, (polygonGroup) => {
      const polygon = new this.props.mapApi.Polygon({
            paths: polygonGroup.path,
            strokeColor: this.props.strokeColor,
            strokeOpacity: this.props.strokeOpacity,
            strokeWeight: this.props.strokeWeight,
            fillColor: this.props.fillColor,
            fillOpacity: this.props.fillOpacity,
            // areaKeyがある場合はクリックされることを想定しているので、前方に出すためzIndexを設定
            zIndex: this.props.areaKey ? 10 : -1
          }
      )
      polygon.addListener("click", (event: any) => {
        if (this.props.onClickPolygon != null) {
          this.props.onClickPolygon()
        }
      });

      polygon.addListener("contextmenu", (event: any) => {
        if (this.props.onRightClickPolygon != null) {
          this.props.onRightClickPolygon()
        }
      });

      polygon.addListener("mousemove", (event: google.maps.PolyMouseEvent) => {
        if (this.props.onMousemove != null) {
          this.props.onMousemove(event)
        }
      })

      polygon.addListener("mouseover", (event: google.maps.PolyMouseEvent) => {
        if (this.props.onMouseover != null) {
          this.props.onMouseover(event)
        }
      });

      polygon.addListener("mouseout", (event: google.maps.PolyMouseEvent) => {
        if (this.props.onMouseout != null) {
          this.props.onMouseout(event)
        }
      })

      polygon.setMap(this.props.map);
      this.polygons.push(polygon)
    })
  }

  private makePathsForMulitiPolygon() {
    const componentsList = this.wkt.components;
    this.polygonGroups = [];
    _.each(componentsList, (components) => {
      let outerPath = [];
      let innerPathes = [];
      _.each(components, (component, index: number) => {
        if (index === 0) {
          // 一つ目は外側
          const outer = component;
          for (let i = 0; i < outer.length; i++) {
            const item = outer[i];
            outerPath.push({lat: item.y, lng: item.x});
          }
        } else {
          // 二つ目以降は内側
          const hole = component;
          innerPathes[index-1] = [];
          for (let i = 0; i < hole.length; i++) {
            const item = hole[i];
            innerPathes[index-1].push({lat: item.y, lng: item.x});
          }
        }
      })

      const alignedPathes = this.alignRotation(outerPath, innerPathes)
      outerPath = alignedPathes.outerPath
      innerPathes = alignedPathes.innerPathes

      if (components.length === 1) {
        this.polygonGroups.push({ path: outerPath })
      } else {
        this.polygonGroups.push({ path: [outerPath, ...innerPathes] })
      }
    })
  }

  private makePathsForPolygon() {
    const components = this.wkt.components;
    this.polygonGroups = [];

    let outerPath = [];
    let innerPathes = [];
    _.each(components, (component, index: number) => {
      if (index === 0) {
        // 一つ目は外側
        const outer = component;
        for (let i = 0; i < outer.length; i++) {
          const item = outer[i];
          outerPath.push({lat: item.y, lng: item.x});
        }
      } else {
        // 二つ目以降は内側
        const hole = component;
        innerPathes[index-1] = [];
        for (let i = 0; i < hole.length; i++) {
          const item = hole[i];
          innerPathes[index-1].push({lat: item.y, lng: item.x});
        }
      }
    })

    const alignedPathes = this.alignRotation(outerPath, innerPathes)
    outerPath = alignedPathes.outerPath
    innerPathes = alignedPathes.innerPathes

    if (components.length === 1) {
      this.polygonGroups.push({ path: outerPath })
    } else {
      this.polygonGroups.push({ path: [outerPath, ...innerPathes] })
    }
  }

  /**
   * パスの回り方を調整するメソッド.
   *
   * DBを使ってST_系の計算を行った場合、
   * 穴があるエリアだとパスの回り方が変わることがあり、地図上にうまく表示するには調整しないといけない。
   * (調整するとたまたまうまく表示できたというだけでそのような記述をどこかの資料から見つけたわけではない)
   * @param outerPath
   * @param innerPathes
   * @returns
   */
  private alignRotation(outerPath: {lat: number; lng: number}[], innerPathes: {lat: number; lng: number}[][]) {
    // outerは左回りにする
    const isRight = this.isTurnRight(outerPath)
    if (isRight) outerPath.reverse()

    // innerは右回りにする
    let alignInnerPathes = []
    for (const innerPath of innerPathes) {
      const isRight = this.isTurnRight(innerPath)
      if (!isRight) innerPath.reverse()
      alignInnerPathes.push(innerPath)
    }

    return {
      outerPath: outerPath,
      innerPathes: alignInnerPathes
    }
  }

  /**
   * ある閉領域の線分を構成する緯度経度の点列が右回りかどうか判定する関数.
   * @param points
   * @returns
   */
  private isTurnRight(points: {lat: number; lng: number}[]) {
    const area = this.getArea(points);
    // S ＞ 0ならばCは左回り，S ＜ 0ならばCは右回り
    return area < 0
  }

  /**
   * 緯度経度の点からその点を結んだ線分に囲まれた部分の面積を求める関数.
   *
   * @param points
   */
  private getArea(points: {lat: number; lng: number}[]) {
    // S ＝ (Σ_1~n (x_i * y_i+1 - x_i+1 * y_i)) / 2
    // x: lng, y: lat
    let area = 0
    for (let i=0;i<points.length-1;i++) {
      area += points[i].lng * points[i+1].lat - points[i+1].lng * points[i].lat
    }
    return area
  }

}

export default WktMultiPolygon;
