import React, {ChangeEvent} from "react";

interface Props {
    hexSize: number,
    onChangeHexSize: (hexSize: number) => void
}

export default function MAPToolboxContentForHex(props: Props) {
    const {hexSize, onChangeHexSize} = props

    const handleChangeHexSize = ((e: ChangeEvent<HTMLInputElement>) => {
        onChangeHexSize(Number(e.target.value))
    })
    return (
        <div>
            <div>
                <div className="row">
                    <label htmlFor="inputPassword" className="col-sm-5 col-form-label">サイズ</label>
                    <div className="col-sm-7">
                        <div className="d-flex">
                            <button className="btn btn-info mr-1"
                                    style={{flex: 1, fontWeight: "bold", fontSize: 12, padding: 4}}
                                    disabled={hexSize <= 1}
                                    onClick={() => onChangeHexSize(hexSize - 1)}
                            >−
                            </button>
                            <input type="number" className="form-control text-right" min={1} max={6}
                                   style={{flex: 3}} value={hexSize} onChange={handleChangeHexSize}/>


                            <button className="btn btn-info ml-1"
                                    style={{flex: 1, fontWeight: "bold", fontSize: 12, padding: 4}}
                                    disabled={hexSize >= 6}
                                    onClick={() => onChangeHexSize(hexSize + 1)}
                            >＋
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}