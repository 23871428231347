import React, { useEffect, useState } from "react";
import {observer} from "mobx-react";
import GoogleMapReact from 'google-map-react';

import CarryStaffSimpleMarker from './CarryStaffSimpleMarker'
import _ from 'lodash'
import MapAttributes from "../constants/MapAttributes";
import carryStaffLocationHistoriesStore from "../stores/CarryStaffLocationHistoriesStore";
import Polyline from "./Polyline";

declare var gon: any;

interface Props {
  carryStaffId: number
}

function CarryStaffMap({ carryStaffId }: Props) {
  const [map, setMap] = useState(null);
  const [mapApi, setMapApi] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [center, setCenter] = useState({ lat: 35.681236, lng: 139.767125 });

  // ストアの位置情報の変更を購読して、地図の中心を動かす
  useEffect(() => {
    const locations = carryStaffLocationHistoriesStore.staffLocations[carryStaffId] || [];
    if (locations.length == 0) return;

    const latestLat = locations[0].lat;
    const latestLng = locations[0].lng;
    if (latestLat === center.lat && latestLng === center.lng) return;

    setCenter({ lat: latestLat, lng: latestLng });
    map?.setCenter({ lat: latestLat, lng: latestLng });
  }, [carryStaffLocationHistoriesStore.staffLocations[carryStaffId]]);

  const locations = carryStaffLocationHistoriesStore.staffLocations[carryStaffId] || [];

  function createCarryStaffMarkers() {
    return _.map(locations.slice(), (location, index) => {
      return (
        <CarryStaffSimpleMarker
          lat={location.lat}
          lng={location.lng}
          sentAt={location.sent_at}
          latest={index === 0}
          key={index}
        />
      )
    })
  }

  function createPolylines() {
    if (!mapLoaded) return;

    let polylineGroups = [];
    let currentGroup = [];
    _.each(locations, location => {
      if (currentGroup.length == 0) {
        currentGroup.push(location);
        polylineGroups.push(currentGroup);
      } else {
        const lastLocation = _.last(currentGroup);
        if (
          (lastLocation.lat - 0.001 <= location.lat && location.lat <= lastLocation.lat + 0.001) &&
          (lastLocation.lng - 0.001 <= location.lng && location.lng <= lastLocation.lng + 0.001)
        ) {
          // 範囲内の座標であれば同じグループに追加する
          currentGroup.push(location)
        } else {
          currentGroup = [location];
          polylineGroups.push(currentGroup);
        }
      }
    });

    return _.map(polylineGroups, (polylineGroup, index) => {
      return (
        <Polyline map={map}
                  mapApi={mapApi}
                  locations={polylineGroup}
                  key={index} />
      )
    })
  }

  return (
    <div className={'request-map-container'}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: gon.google_api_key
        }}
        defaultCenter={{lng: center.lng, lat: center.lat}}
        defaultZoom={16}
        center={{lng: center.lng, lat: center.lat}}
        resetBoundsOnResize={true}
        hoverDistance={MapAttributes.K_SIZE / 2}
        onGoogleApiLoaded={({map, maps}) => {
          setMap(map);
          setMapApi(maps);
          setMapLoaded(true);
        }}
        yesIWantToUseGoogleMapApiInternals={true}
      >
        {createCarryStaffMarkers()}
        {createPolylines()}
      </GoogleMapReact>
    </div>
  );
}

export default observer(CarryStaffMap);
