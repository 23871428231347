import React from "react";
import {mapMarkerPinStyle, mapMarkerPlaceStyle} from '../styles/StoreMarkerStyles'
import SpotRemarkMarkerMenu from "./SpotRemarkMarkerMenu";

interface Props {
  markerKey: number,
  editable: boolean,
  lat: number,
  lng: number,
  calcMenuPosition: Function,
  onClickDelete: Function,
  isCenter: boolean,
  zoomLevel?: number,
  // GoogleMap pass $hover props to hovered components
  // to detect hover it uses internal mechanism, explained in x_distance_hover example
  $hover?: boolean,
}

interface State {
  showInfoWindow: boolean
}


// OverlookTempMarkerからではなく、AddressMarkerからパクる
// OverlookTempMarkerだとマーカーの初回クリック時にマーカーをクリックしたと判定されず、
// 再度マーカーが置かれてしまう。多分mapsApiで描画しているためのタイミングや描画まわりの不具合
export default class SpotRemarkTempMarker extends React.Component<Props, State> {

  menu: SpotRemarkMarkerMenu = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      showInfoWindow: false
    };
  }

  render() {
    return (
      <div>
        <div
          style={mapMarkerPinStyle}
          onContextMenu={this.onContextMenu}
        >
          {/*<i className={"fa fa-map-pin"} />*/}
          {this.props.isCenter ? <i className={"fa fa-map-marker-alt spot-remarks-center-marker-color"} />
            : <i className={"fa fa-map-marker-alt"}/>}
        </div>
        {!this.props.isCenter &&
          <SpotRemarkMarkerMenu
          onMenuItemClick={this.onMenuItemClick}
          ref={(node) => this.menu = node}
          />
        }
      </div>
    )
  }

  private onClickMarker(event: Event) {
    this.setState({showInfoWindow: !this.state.showInfoWindow})
    event.preventDefault();
    event.stopPropagation();
  }

  // 右クリックイベントハンドラ
  onContextMenu = (event) => {
    // preventDefault()を忘れると、普通の右クリックメニューが表示されますよ。
    event.preventDefault();

    if (!this.props.editable) {
      return; // 閲覧専用の時は表示しない
    }
    if (this.props.isCenter) { // 中心の場合は表示しない
      return;
    }
    // // メニュー要素の"show()"メソッドを呼び出します。
    // // 引数にはマウスポインタの位置情報を渡してあげます。
    const position = this.props.calcMenuPosition(event.clientX, event.clientY);
    // this.menu.show(position.x, position.y);
    this.menu.show(0, 0);
  }

  // onMenuItemClick(message) {
  onMenuItemClick = () => {
    // 削除だけなので判定は不要
    this.props.onClickDelete(this.props.markerKey);
  }
}
