import { action, observable, set } from "mobx";
import _ from "lodash";
import axios from 'axios';
import OfficesResponse from "../interfaces/OfficesResponse";
import { VisibleOfficeModel } from "../models/VisibleOfficeModel";
import { VisibleServiceAreaModel } from "../models/VisibleServiceAreaModel";
import ServiceAreasResponse from "../interfaces/ServiceAreasResponse";
import {VisibleRequestModel} from "../models/VisibleRequestModel";
import {VisibleCarryStaffModel} from "../models/VisibleCarryStaffModel";

declare var google: any;

export class CarryStaffOverlookStore {
  visibleOffices = observable<VisibleOfficeModel>([]);
  visibleServiceAreas = observable<VisibleServiceAreaModel>([]);
  notExistStaffServiceAreas = observable<VisibleServiceAreaModel>([]);

  @observable
  vendorCompanyRange: number = 30;

  @observable
  serviceAreaRange: number = 1500;

  @observable
  requestVisible: boolean = true;

  map: any;

  constructor() {
    this.loadOffices();
    //this.loadServiceAreas();//loadInterSectServiceAreasに置き換えのためCO
    this.loadNotExistStaffServiceAreas();
  }

  @action
  public setMap(map) {
    this.map = map;
  }

  @action
  public async loadOffices() {
    const response = await axios.get('/api/offices', {
      params: {}
    });
    const results = response.data;

    const offices = _.map(results, (result: OfficesResponse) => {
      return new VisibleOfficeModel(result)
    });

    this.replaceOffices(offices)
  }

  @action
  private replaceOffices(offices) {
    this.visibleOffices.replace(offices)
  }

  @action
  public async loadServiceAreas() {//loadInterSectServiceAreas の追加により不要？
    const response = await axios.get('/api/service_areas', {
      params: {}
    });
    const results = response.data;

    const serviceAreas = _.map(results, (result: ServiceAreasResponse) => {
      return new VisibleServiceAreaModel(result)
    });

    this.replaceServiceAreas(serviceAreas)
  }

  @action
  public async loadInterSectServiceAreas(ne, se, sw, nw) {
    const response = await axios.get('/api/intersect_service_areas', {
      params: {
        neLng: String(ne.lng),
        neLat: String(ne.lat),
        seLng: String(se.lng),
        seLat: String(se.lat),
        swLng: String(sw.lng),
        swLat: String(sw.lat),
        nwLng: String(nw.lng),
        nwLat: String(nw.lat)
      }
    });
    const results = response.data;

    const serviceAreas = _.map(results, (result: ServiceAreasResponse) => {
      return new VisibleServiceAreaModel(result)
    });

    this.replaceServiceAreas(serviceAreas)
  }

  @action
  public async loadNotExistStaffServiceAreas() {
    const response = await axios.get('/api/not_exist_staff_service_areas', {
      params: {}
    });
    const results = response.data;

    const serviceAreas = _.map(results, (result: ServiceAreasResponse) => {
      return new VisibleServiceAreaModel(result)
    });

    this.replaceNotExistStaffServiceAreas(serviceAreas)
  }

  @action
  private replaceServiceAreas(serviceAreas) {
    this.visibleServiceAreas.replace(serviceAreas)
  }

  @action
  private replaceNotExistStaffServiceAreas(serviceAreas) {
    this.notExistStaffServiceAreas.replace(serviceAreas)
  }

  @action
  public toggleOfficeVisible(cisibleOfficeModel) {
    const visibleVendorOffice = _.find(this.visibleOffices, (object) => {
      return object.id == cisibleOfficeModel.id
    })
    visibleVendorOffice.visible = !visibleVendorOffice.visible;
    this.replaceOffices(this.visibleOffices);
  }

  @action
  public toggleServiceAreaVisible(visibleServiceAreaModel) {
    const visibleServiceArea = _.find(this.visibleServiceAreas, (object) => {
      return object.id == visibleServiceAreaModel.id
    })
    visibleServiceArea.visible = !visibleServiceArea.visible;
    this.replaceServiceAreas(this.visibleServiceAreas);
  }

  @action
  public viewAllServiceAreas() {
    _.each(this.visibleServiceAreas, (object) => {
      object.visible = true
    })
    this.replaceServiceAreas(this.visibleServiceAreas);
  }

  @action
  public hiddenAllServiceAreas() {
    _.each(this.visibleServiceAreas, (object) => {
      object.visible = false
    })
    this.replaceServiceAreas(this.visibleServiceAreas);
  }

  @action
  public viewAllRequests() {
    this.requestVisible = true;
  }

  @action
  public hiddenAllRequests() {
    this.requestVisible = false;
  }

  @action
  public setVendorCompanyRange(meter: number) {
    this.vendorCompanyRange = meter
  }
}

const singleton = new CarryStaffOverlookStore();
export default singleton
