import { action, observable, set } from "mobx";

export class CarryStaffModel {
  @observable.ref
  id: number;

  @observable.ref
  key: number;

  name: string;
  lat: number;
  lng: number;

  constructor({id, name, lat, lng}) {
    this.id = id;
    this.key = id;
    this.name = name;
    this.lat = lat;
    this.lng = lng;
  }
}
