import React from "react";
import axios from 'axios';
import {observer} from "mobx-react";
import 'react-toastify/dist/ReactToastify.css';

import DeliveryArea from "../interfaces/DeliveryArea";
import DeliveryAreaMapWithInputWkt from "./DeliveryAreaMapWithInputWkt";

interface Props {
  vendorCompanyId: number
  deliveryArea: DeliveryArea
}

class DeliveryAreaMapWithInputWktVendorCompany extends React.Component<Props> {

  render() {
    return (
      <DeliveryAreaMapWithInputWkt
        deliveryArea={this.props.deliveryArea}
        updateDeliveryArea={async (deliveryAreaWkt) => {
          return await axios.post(
            `/api/vendor_companies/${this.props.vendorCompanyId}/update_delivery_area_wkt`,
            {delivery_area: deliveryAreaWkt})
        }}
        deleteDeliveryArea={async () => {
          return await axios.post(`/api/vendor_companies/${this.props.vendorCompanyId}/delete_delivery_area`)
        }}

      />
    );
  }

}

export default observer(DeliveryAreaMapWithInputWktVendorCompany);
