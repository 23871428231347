import React from "react";
import { observer } from "mobx-react";
import GoogleMapReact from 'google-map-react';
import MapAttributes from "../constants/MapAttributes";
import ServiceArea from "../interfaces/ServiceArea";
import ServiceAreaMarker from "../components/ServiceAreaMarker";
import WktMultiPolygon from "../components/WktMultiPolygon";

declare var gon: any;

interface Props {
  serviceArea: ServiceArea
}

interface State {
  map: any,
  mapApi: any,
  mapLoaded: boolean
}


class ServiceAreaMap extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      map: null,
      mapApi: null,
      mapLoaded: false
    };
  }

  componentDidMount(): void {
  }

  render() {
    const { serviceArea } = this.props;
    return (
      <div className={'request-map-container'}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: gon.google_api_key
          }}
          defaultCenter={serviceArea}
          defaultZoom={13}
          center={serviceArea}
          resetBoundsOnResize={true}
          hoverDistance={MapAttributes.K_SIZE / 2}
          onGoogleApiLoaded={({ map, maps }) => this.setState({
            map: map,
            mapApi: maps,
            mapLoaded: true
          })}
        >
          {(this.state.mapApi) && (
            <WktMultiPolygon
              map={this.state.map}
              mapApi={this.state.mapApi}
              wktText={serviceArea.within_area_wkt}
            />
          )}
        </GoogleMapReact>
      </div>
    );
  }

}

export default observer(ServiceAreaMap);
